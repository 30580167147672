export enum TRANSACTION_TYPE {
    TNX_CODE_CREDITS = '768',
    TNX_CODE_CHARGES = '769',
    TNX_CODE_TRANSFER_TO_BANK = '769',
    MERCHANT_NAME_CHARGES = 'חיוב כרטיס אשראי', // was as default - not in use
    MERCHANT_NAME_TRANSFER_TO_BANK = 'משיכה לחשבון בנק' // was as default - not in use
}

export interface IGetPaymentClearingUrlPayload {
    sum: number;
    description?: string;
    payments?: number;
    failedUrl: string;
    successUrl: string;
}

export interface ValidatePayments {
    token: string;
}

export interface IGetPaymentClearingUrlResponse {
    success: boolean;
    unixtime: number;
    url: string;
}

export interface IGetMaxAccountPaymentsDetails {
    chooseTitle?: string;
    chooseAmountTitle?: string;
    chooseAmountMin?: string;
    chooseAmountMax?: string;
    chooseDescriptionTitle?: string;
    chooseLinkButtonText?: string;
    choosePaymentButtonText?: string;
    sendTitle?: string;
    sendSubTitle?: string;
    sendOptionSmsTitle?: string;
    sendOptionSmsOrder?: number;
    sendOptionMailTitle?: string;
    sendOptionMailOrder?: number;
    sendOptionWhatsappTitle?: string;
    sendOptionWhatsappOrder?: number;
    sendWhatsappDescription?: string;
    sendWhatsappButtonText?: string;
    sendAfterOptionsText?: string;
    sendChooseContactText?: string;
    sendButtonText?: string;
    sendAsterix?: string;
    successTitle?: string;
    successSubTitle?: string;
    successDetailsTitle?: string;
    successLogo?: string;
    successButtonText?: string;
    sendSuccessTitle?: string;
    sendSuccessLogo?: string;
    sendSuccessSubTitle?: string;
    sendSuccessTipTitle?: string;
    sendSuccessTipText?: string;
    sendSuccessButtonText?: string;
    cancelContent?: IMaxAccountCancelContent;
    transferContent?: IMaxAccountTransferContent;
    futureIncomesContent?: IMaxAccountFutureIncomesContent;

}

export interface ICreatePaymentUrlFormInput {
    name: string;
    phoneNumber?: string;
    email?: string;
}

export interface ICreatePaymentUrlAndSendInput {
    apiKey?: string;
    customerName: string;
    businessName?: string;
    customerPhone?: string;
    customerEmail?: boolean;
    sum: number;
    sendSms?: string;
    sendEmail?: string;
    autoBalance?: string;
    currencyIso?: string;
    maxPayments?: string;
    successUrl?: string;
    uaUuid?: string;
    comment: string;
    paymentUrl?: string;
    actionType: actionTypes;
}

export interface ICreatePaymentUrlAndSendResponse {
    url: string;
    businessName: string;
    success: boolean;
    isAvailableCoupons: boolean;
}

export enum actionTypes {
    NONE,
    SMS,
    MAIL,
    WHATSAPP
}

export interface IGetMaxAccountPaymentsDetailsChoose {
    chooseTitle: string;
    chooseAmountTitle: string;
    chooseAmountMin: string;
    chooseAmountMax: string;
    chooseDescriptionTitle: string;
    chooseLinkButtonText: string;
    choosePaymentButtonText: string;
}

export interface IValidatePaymentResponse {
    cgpShvaTransacionId?: string;
    cgpCustomerCc4Digits?: string;
    cgpCustomerName?: string;
    cgpPaymentTotal?: number | string;
    cgpDate?: string;
    success?: boolean;
    transactionDescription?: string;
}

export interface IGetMaxAccountPaymentRequestDetailsInput {
    guid: string;
}

export interface IGetMaxAccountPaymentRequestDetails {
    guid: string;
    customerName: string;
    customerPhone?: string;
    customerEmail?: boolean;
    sum: number;
    comment: string;
    dateCreated: Date;
    dateUsed: Date;
    businessName: string;
    asterixSendPaymentRequest: string;
}

export interface ICancelAccountCrmLeadResponse {
    crmOpen: string;
}

export interface MaxAccountDetailsResponse {
    waitingRequestsSum: number;
    allowedToReceiveMoneyReason: number;
    allowedToReceiveMoneyStatus: number;
    allowedToWithdrawMoneyStatus?: number;
    isAllowedToWithdrawMoney: boolean;
    isMerchantExists: boolean;
    isWithdrawAgainAllowed: boolean;
    numOfWaitingRequests: number;
    otb: number;
    paymentRequests: PaymentRequest[];
    waitingWithdraws: WaitingWithdraws[];
    newTransactionsDetailsResult: ITransactionsDetails;
    cards: any;
    bankData: BankInfo;
    businessName: string;
    waitingWithdrawAmount: number;
    merchantNumber: string;
    isCardValid: boolean;
    cardIndex?: number;
    currentCardNumber?: {
        ncryptionKeyInSettings?: string;
        isEncrypted?: false;
        value?: string;
    };
    nextDebitCycleDate?: string;
    merApprovalsNoTrans?: IMerApprovalsNoTrans[];
    getBusinessAuthorizationsRc: number;
    futureCreditDataTotalAmount: number;
}

export interface IMerApprovalsNoTrans {
    Amount: number;
    AuthCode: string;
    CardNumber: string;
    CurrencyCode: number;
    FirstPaymentValue: number;
    MerchantNum: number;
    NumberOfPayments: number;
    PlanCms: number;
    PlanCmsDesc: string;
    ApproveDateTime?: string;
}

export interface GetPaymentsFetchDataResponse {
    waitingRequestsSum: number;
    paymentRequests: PaymentRequest[];
}

export interface BankInfo {
    bankName: string;
    bankId: number;
    branchId: number;
    accountId: number;
}

export interface PaymentRequest {
    customerName: string;
    customerPhone: string;
    customerEmail: string;
    reminderUrl: string;
    status: 'waiting' | string;
    transactionAmount: number;
    transactionCurrency: string;
    transactionDate: Date;
    statusText: string;
    sendingWay: string;
    nameCss: string;
    comment: string;
    reason: string;
}

export interface WaitingWithdraws {
    withdrawAmount: number;
    withdrawDate: Date;
    merchantDescription?: string;
}

export interface IGetMaxAccountDashboardContent {
    dashboardTitle: string;
    dashboardTabBalanceText: string;
    dashboardTabNotPaidText: string;
    dashboardBalanceTitle: string;
    dashboardNotPaidTitle: string;
    dashboardBalanceLinkText: string;
    dashboardNotPaidLinkText: string;
    dashboardNewAccountTitle: string;
    dashboardNewAccountImage: string;
    dashboardNewAccountButtonText: string;
    dashboardNewAccountButtonUrl: string;
    dashboardActionLinks: IDashboardLink[];
    dashboardAsterix: string;
    headerLinkNewPaymentTitle: string;
    headerLinkNewPaymentLogo: string;
    headerLinkBankTransferTitle: string;
    headerLinkBankTransferLogo: string;
    headerLinkAllActionsTitle: string;
    headerLinkAllActionsLogo: string;
    asterixDashboard: string;
    asterixTransactions: string;
    asterixAccountTransfer: string;
    asterixPaymentHistory: string;
}

export interface IMaxAccountRecommendationsData {
    recommendations: IDashboardRecommendation[];
}

export interface IDashboardRecommendation {
    id: number;
    name: string;
    title: string;
    subTitle: string;
    backgroundImage: string;
    backgroundColor: string;
    textColor: string;
    link: string;
    asterix: string;
    externalRecommendationId: number;
}

export interface IDashboardLink {
    text: string;
    link: string;
}

export interface ITransactionsDetails {
    cardsData: ICardsData[];
    categories: any[];
    isShowCreditLimitMsg: boolean;
    totalCycleDebit: any;
    validityDate: Date;
}

export interface ICardsData {
    authorizationData: any;
    cycleDebit: any;
    maskedCardNumber: string;
    returnCode: number;
    transactionData: ITransaction[];
}

export interface ITransaction {
    actualPaymentAmount: number;
    txnCode: string;
    merchantName: string;
    purchaseDate: string;
    opeN_TO_BUY_DAILY?: number;
    shortCardNumber?: string;
    processingDate?: string;
}

export interface IMaxAccountCancelContent {
    cancelDesktopImage: string;
    cancelMobileImage: string;
    cancelDesktopText: string;
    cancelMobileText: string;
    cancelButtonText: string;
    cancelLinkText: string;
    cancelAsterix: string;
}

export interface IMaxAccountTransferContent {
    accountTransferTitle: string;
    accountTransferAmountText: string;
    accountTransferPercent: number;
    accountTransferPercentText: string;
    accountTransferPercentTooltip: string;
    accountTransferAccountText: string;
    accountTransferHelpText: string;
    accountTransferAsterix: string;
    accountTransferButtonText: string;
}

export interface IMaxAccountFutureIncomesContent {
    futureIncomesTitle: string;
    futureIncomesSubTitle: string;
    futureIncomesAcurotTitle: string;
    futureIncomesApprovalsTitle: string;
    futureIncomesFutureTitle: string;
    isFutureExpansionOpen: boolean;
    isAcurotExpansionOpen: boolean;
    isApprovalsExpansionOpen: boolean;
}

export interface IWithdrawalFromMaxAccountInput {
    amount: number;
}

export interface IWithdrawalFromMaxAccountSuccessPageData {
    date: Date;
    amount: string;
    comissionAmount: string;
    comissionPersent: string;
    bankData: string;
}

export interface IMaxAccountMessage {
    showCloseButton: boolean;
    closeOnAction: boolean;
    img: string;
    title: string;
    text: string;
    btnText: string;
    btnUrl: string;
    btnUrlType: UrlType;
    linkText: string;
    linkUrl: string;
    linkUrlType: UrlType;
    type: MaxAccountMassageTypes;
    isButtonBack?: boolean;
    extraData1?: string;
}

export enum UrlType {
    NoUrl,
    Popup,
    Internal,
    Personal,
    Business,
    FullUrl
}

export enum MaxAccountMassageTypes {
    NoMaxAccount,
    GeneralError,
    NotAllowedToWithdraw,
    NotAllowedToWithdrawPlBlocked,
    NotAllowedToReceive,
    HasActiveWithdraw,
    NoBalance,
    CardBlock,
    ParcialWithdraw,
    MaxAmountError,
    PaymentCancelSuccess,
    PaymentCancelConfirm,
    PaymentCancelError,
    ApiClearingNotAvilable
}

export const MaxAccountGeneralError: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: 'אוי.. משהו השתבש',
    text: 'לא הצלחנו להשלים את הבקשה שלך, יהיה ניתן לנסות שוב מאוחר יותר.',
    btnText: 'תודה',
    btnUrlType: UrlType.Internal,
    btnUrl: '/max-account/personal/dashboard',
    linkText: '', // הבעיה ממשיכה? צרו איתנו קשר
    linkUrl: '',
    linkUrlType: UrlType.Business,
    type: MaxAccountMassageTypes.GeneralError
};

export const MaxAccountNotAllowedToReceive: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: '',
    text: 'לא נוכל להמשיך בתהליך. לפרטים נוספים וטיפול חשוב שנדבר <br><a href="tel:036177766">03-6177766</a>',
    btnText: 'תודה',
    btnUrl: '',
    btnUrlType: UrlType.NoUrl,
    linkText: '',
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.NotAllowedToReceive
};

export const MaxAccountNotAllowedToWithdraw: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: '',
    text:
        'סטטוס בית העסק השתנה ולכן לא נוכל להמשיך בתהליך המשיכה. לפרטים נוספים וטיפול חשוב שנדבר <a href="tel:036177766">03-6177766</a>',
    btnText: 'תודה',
    btnUrlType: UrlType.NoUrl,
    btnUrl: '',
    linkText: '',
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.NotAllowedToWithdraw
};

export const MaxAccountNotAllowedToWithdrawPlBlocked: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: '',
    text:
        'סטטוס השירות השתנה ולכן לא נוכל להמשיך בתהליך המשיכה. יש ליצור איתנו קשר בטלפון <a href="tel:036177766">03-6177766</a>',
    btnText: 'תודה',
    btnUrlType: UrlType.NoUrl,
    btnUrl: '',
    linkText: '',
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.NotAllowedToWithdraw
};

export const MaxAccountMaxAmountError: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: '',
    text: 'משהו לא מסתדר לנו בסכום שבחרת <br> באפשרותך למשוך עד ₪@extraData1@',
    btnText: 'אני רוצה לעדכן את הסכום',
    btnUrlType: UrlType.Internal,
    btnUrl: '/max-account/personal/dashboard/account-transfer',
    linkText: 'לא עכשיו',
    linkUrl: '/max-account/personal/dashboard',
    linkUrlType: UrlType.Internal,
    type: MaxAccountMassageTypes.MaxAmountError
};

export const MaxAccountHasActiveWithdraw: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: '',
    text: 'בוצעה משיכה ב-3 הימים האחרונים, אפשר לנסות שוב מחר.',
    btnText: 'תודה',
    btnUrlType: UrlType.Internal,
    btnUrl: '/max-account/personal/dashboard',
    linkText: '',
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.HasActiveWithdraw
};

export const MaxAccountNoBalance: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: '',
    text: 'היתרה בm_a_x עצמאיים שלך שווה ל 0 ₪ ולכן לא נוכל לאפשר לך לבצע משיכה',
    btnText: 'תודה',
    btnUrl: '/max-account/personal/dashboard',
    btnUrlType: UrlType.Internal,
    linkText: '',
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.NoBalance
};

export const MaxAccountCardBlock: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: '',
    text:
        'כרטיס האשראי שלך חסום בקוד חסימה שלא מאפשר לנו להשלים את פעולת המשיכה. כדי שנוכל לטפל בזה חשוב שנדבר <a href="tel:036177766">03-6177766</a>',
    btnText: 'תודה',
    btnUrlType: UrlType.Internal,
    btnUrl: '/max-account/personal/dashboard',
    linkText: '',
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.CardBlock
};

export const MaxAccountParcialWithdraw: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/music-balls.svg',
    title: 'ביקשת למשוך סכום חלקי',
    text: 'לתשומת לבך, אפשר יהיה למשוך שוב כסף מהחשבון בעוד 3 ימים',
    btnText: 'אישור',
    btnUrl: '',
    btnUrlType: UrlType.Popup,
    linkText: 'בעצם לא',
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.ParcialWithdraw
};

export const MaxAccountPaymentCancelConfirm: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '',
    title: 'רגע לפני שנבטל את בקשת התשלום',
    text: 'בקשת התשלום ששלחת ל@extraData1@ תבוטל <br /> ולא ניתן יהיה לשלם דרכה',
    btnText: 'תבטלו לי את בקשת התשלום',
    btnUrl: '',
    btnUrlType: UrlType.Popup,
    linkText: 'לחצתי בטעות על ביטול בקשת תשלום',
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.PaymentCancelConfirm
};

export const MaxAccountPaymentCancelError: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: 'לא הצלחנו להשלים את הבקשה',
    text: 'אפשר לנסות שוב עכשיו או מאוחר יותר',
    btnText: 'תודה',
    btnUrl: '',
    btnUrlType: UrlType.Popup,
    linkText: '',
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.PaymentCancelError
};

export const MaxAccountPaymentCancelSuccess: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/rocket.svg',
    title: 'בקשת התשלום ל@extraData1@ בוטלה בהצלחה',
    text: 'מומלץ לשתף שבקשת התשלום בוטלה. <br><br><br> אם התחרטת יש לשלוח ל@extraData1@ בקשת תשלום חדשה',
    btnText: 'שיתוף ב WHATSAPP',
    btnUrlType: UrlType.Popup,
    btnUrl: '',
    linkText: 'תודה', // הבעיה ממשיכה? צרו איתנו קשר
    linkUrl: '',
    linkUrlType: UrlType.NoUrl,
    type: MaxAccountMassageTypes.GeneralError
};

export const MaxAccountNoMaxAccount: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: 'זה קורה לעמודים הטובים ביותר',
    text: `בדקנו, ולא הצלחנו למצוא m_a_x עצמאיים על שמך <br /> <br /> <br /> עצמאים, מהיום מקבלים תשלום באפליקציית max ללא הגבלה וללא עמלה להצטרפות ופרטים נוספים`,
    btnText: 'מעניין אותי',
    btnUrl: 'https://businesslc.max.co.il/pay/max-account',
    btnUrlType: UrlType.FullUrl,
    linkText: 'לא כרגע',
    linkUrl: '/',
    linkUrlType: UrlType.Internal,
    type: MaxAccountMassageTypes.NoMaxAccount
};

export const ApiClearingNotAvilable: IMaxAccountMessage = {
    showCloseButton: false,
    closeOnAction: true,
    img: '/assets/images/max-account/turtle.svg',
    title: 'אנחנו עובדים על הצטרפותך לחשבון עצמאים',
    text: `לבנתיים אפשר לשלוח בקשת תשלום באמצעות WHATSAPP,SMS או מייל`,
    btnText: 'לשליחת בקשת תשלום',
    btnUrl: '/max-account/personal/dashboard',
    btnUrlType: UrlType.Internal,
    linkText: 'לא כרגע',
    linkUrl: '/max-account/personal/payment/choose',
    linkUrlType: UrlType.Internal,
    type: MaxAccountMassageTypes.ApiClearingNotAvilable
};

export const MAX_ACCOUNT_MASSAGE_PAGE_STATES = {
    [MaxAccountMassageTypes.NoMaxAccount]: MaxAccountNoMaxAccount,
    [MaxAccountMassageTypes.GeneralError]: MaxAccountGeneralError,
    [MaxAccountMassageTypes.PaymentCancelSuccess]: MaxAccountPaymentCancelSuccess,
    [MaxAccountMassageTypes.NotAllowedToWithdraw]: MaxAccountNotAllowedToWithdraw,
    [MaxAccountMassageTypes.NotAllowedToWithdrawPlBlocked]: MaxAccountNotAllowedToWithdrawPlBlocked,
    [MaxAccountMassageTypes.NotAllowedToReceive]: MaxAccountNotAllowedToReceive,
    [MaxAccountMassageTypes.HasActiveWithdraw]: MaxAccountHasActiveWithdraw,
    [MaxAccountMassageTypes.NoBalance]: MaxAccountNoBalance,
    [MaxAccountMassageTypes.CardBlock]: MaxAccountCardBlock,
    [MaxAccountMassageTypes.PaymentCancelConfirm]: MaxAccountPaymentCancelConfirm,
    [MaxAccountMassageTypes.PaymentCancelError]: MaxAccountPaymentCancelError,
    [MaxAccountMassageTypes.ParcialWithdraw]: MaxAccountParcialWithdraw,
    [MaxAccountMassageTypes.MaxAmountError]: MaxAccountMaxAmountError,
    [MaxAccountMassageTypes.ApiClearingNotAvilable]: ApiClearingNotAvilable
};

export interface IGetMaxAccountNewCreditByTranData {
    settlementByTranList: ISettlementByTranItem[];
}

export interface ISettlementByTranItem {
    currentTermNetAmount: number;
    currentTermGrossAmount: number;
    cardNumber4Digits: string;
    depositDate: Date;
    planDesc;
    numberOfPayments: number;
    tranDate: Date;
    settDate: Date;
    currencyId: string;
    tranAmount: number;
    isRegular: boolean;
    numOfRecords?: number;
}

export interface IMaxAccountTransactionDataExcel {
    transactions: ITransaction[];
}

export interface IMaxAccountTransactionExcel {
    Date?: string;
    Description: string;
    CardNumber?: string;
    PaymentAmount?: number;
    Currency?: string;
    ProcessingDate?: string;
    OpenToBuyDaily?: number | null;
    DailySumCurrency?: string;
}

export interface IMaxAccountExcelPaymentHistory {
    Date?: string;
    Name?: string;
    Type?: string;
    Status?: string;
    Description?: string;
    PaymentAmount?: number;
    Currency?: string;
}

export interface SortedTransaction {
    date: number;
    date_formatted?: Date;
    credits?: Array<{ credits?: ITransaction[] }>;
    charges?: ITransaction[];
    transferToAccount?: ITransaction[];
    waitingWithdrawsTransferToAccount?: WaitingWithdraws[];
    waitingWithdrawscharges?: WaitingWithdraws[];
    waitingWithdrawscredits?: WaitingWithdraws[];
    waitingWithdrawsExtraCharge?: ITransaction[];
}

export interface FutureIncome {
    date: string;
    cardNum: string;
    incomeSum: string;
    payment: string;
    trueDate: string;
    cardNumber: string;
    paymentInfo: string;
}

export interface IGetMaxAccountNewCreditByTranRequest {
    branches: string;
    fromCreditDate: Date;
    toCreditDate: Date;
    transactionsType?: number;
    pageSize?: number;
    fromLine?: number;
}

export interface ImyMaxCard {
    nameHeb: string;
    cellPhone: string;
    parentCard: string;
    otb: number;
    blockCode: string;
    colorId: number;
    digitalCardInd: string;
    catalogID: string;
    expirationDate: Date;
    mutag: string;
    dateOpened: Date;
    index: number;
    indexInUserContext: number;
    parentCardName: string;
    parentCardShortNumber: string;
    childCardShortnumber: string;
    nextBillingCycleDate: Date;
    directDebitAmount: number;
    directDebitDay: number;
}
