import { Injectable, Inject, Optional, EventEmitter } from '@angular/core';
import { ModalService } from '../../shared/modules/bs-modal/services/modal.service';
import { IModalConfig } from '../../shared/modules/bs-modal/interfaces/modal-config.interface';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { IResponse, HttpService } from '../../shared/cardholders-core/services/http-service.service';
import { AuthService } from '../../shared/cardholders-core/services/auth.service';
import { AppConfigsService } from '../../config/app-configs.service';
import {
    ILoginResult,
    IUserTransferResult,
    PopupType,
    UserTransferReason
} from '../../shared/cardholders-core/models/login-result';
import { ILoginOtpResult } from './models/login-otp-result';
import { Router } from '@angular/router';
import { ReturnUrlService } from '../../shared/cardholders-core/services/return-url.service';
import { LoginWithMaxService } from '../login-with-max/login-with-max.service';
import { tap } from 'rxjs/operators';
import { LoginStatus } from '../../shared/cardholders-core/models/login-result';
import { EloquaService } from '../../shared/cardholders-core/services/eloqua.service';
import { UiHelperService } from 'core-max-lib';
import { OpenBankingService } from '../open-banking/open-banking.service';
import { DigitalTransferService } from '../digital-transfer/services/digital-transfer.service';
import { TrusteerSnippetsService } from '../../shared/cardholders-core/services/trusteer-snippets.service';
import { Ave8Service } from '../../shared/cardholders-core/services/ave8.service';
import { GenericSsoService } from '../generic-sso/generic-sso.service';

const LoginPopup: IModalConfig = {
    content: LoginPopupComponent,
    options: { class: 'login-popup modal-dialog-centered' },
    type: 'LoginPopup'
};

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    returnUrl: string;
    loginPageMode = false;
    originUrl;
    needId: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    userTransferResult: IUserTransferResult;

    constructor(
        private httpSvc: HttpService,
        private appConfigsService: AppConfigsService,
        private authService: AuthService,
        private modalSvc: ModalService,
        private router: Router,
        private loginWithMaxSvc: LoginWithMaxService,
        private openBankingSvc: OpenBankingService,
        private digitalTransferSvc: DigitalTransferService,
        private returnUrlSvr: ReturnUrlService,
        private eloquaService: EloquaService,
        private uiHelper: UiHelperService,
        private trusteerSnippetsService: TrusteerSnippetsService,
        private genericSsoService: GenericSsoService,
        @Optional() @Inject('serverUrl') private serverUrl: string
    ) {
        this.originUrl = this.uiHelper.isBrowser ? window.location.origin : this.serverUrl;
    }

    login(username: string, password: string, idNumber: string): Observable<IResponse<ILoginResult>> {
        return this.authService.login(username, password, idNumber).pipe(
            tap((data: IResponse<ILoginResult>) => {
                if (
                    data.Result.LoginStatus === LoginStatus.success ||
                    data.Result.LoginStatus === LoginStatus.NeedToShowInsurance
                ) {
                    this.eloquaService.sendElqCustomer();
                }
            })
        );
    }

    logoff() {
        return this.authService.logoff();
    }

    loginOtp(id: string): Observable<IResponse<ILoginOtpResult>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/login/otpLogin`, {
            id: Number.parseInt(id)
        });
    }

    loginMailOtp(id: string): Observable<IResponse<ILoginOtpResult>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/login/mailOtpLogin`, {
            id: Number.parseInt(id)
        });
    }

    verifyEmail(email: string): Observable<IResponse<any>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/login/verifyEmail`, {
            email: email
        });
    }

    loginOtpVerify(
        id: string,
        card8Digits: string,
        verifyCode: string,
        accountNumber: string,
        isTypeIsCard8Digits: boolean
    ): Observable<IResponse<ILoginResult>> {
        return this.authService.loginOtpVerify(id, card8Digits, verifyCode, accountNumber, isTypeIsCard8Digits).pipe(
            tap((data: IResponse<ILoginResult>) => {
                if (
                    data.Result.LoginStatus === LoginStatus.success ||
                    data.Result.LoginStatus === LoginStatus.NeedToShowInsurance
                ) {
                    this.eloquaService.sendElqCustomer();
                }
            })
        );
    }

    showPopup(returnUrl: string = '') {
        this.modalSvc.openModal(LoginPopup);
        document.querySelector('.modal-backdrop').classList.add('modal-login-backdrop');
        this.returnUrl = returnUrl;
        this.returnUrlSvr.returnUrl = returnUrl;
    }

    showDynamicPopup(modalConfig: IModalConfig) {
        this.modalSvc.openModal(modalConfig);
        document.querySelector('.modal-backdrop').classList.add('modal-login-backdrop');
    }

    loginWithMax() {
        this.router.navigate(['/loginwithmax/scopes'], { queryParamsHandling: 'merge' });
        return;
    }

   

    navigateReturnUrlAfterLoginSuccess() {
        localStorage.removeItem("authPassed");
        localStorage.removeItem("authPassedOTP");
        localStorage.removeItem("authPassedCard");
        const loginWithMaxMode = this.loginWithMaxSvc.isLoginWithMaxMode();
        const openBankingLoginMode = this.openBankingSvc.isOpenBankingLoginMode();
        const digitalTransferCardLoginMode = this.digitalTransferSvc.isDigitalTransferCardLoginMode();
       const genericSsoLoginMode = this.genericSsoService.isLoginWithSsoMode();
        if (loginWithMaxMode) {
            this.loginWithMax();

        } else if (genericSsoLoginMode) {
            this.router.navigate(['/sso-navigator/login-sucess']);
        }
        else if (openBankingLoginMode) {
            this.openBankingSvc.onOpenBankingLoginSuccess.next(true);
        } else if (digitalTransferCardLoginMode) {
            this.digitalTransferSvc.onDigitalTransferCardLoginSuccess.next(true);
        } else {
    this.trusteerSnippetsService.executeTagmastersEvent('main_account');
            if (this.authService.isContactAuthorization()) {
                this.router.navigate(['/transaction-details/personal']);
            } else {
                if (this.userTransferResult && this.userTransferResult.IsTransferMessageNeeded) {
                    if (this.userTransferResult.TransferReason !== UserTransferReason.none) {
                        this.router.navigate(['/user-transfer/personal/auto']);
                    } else if (
                        this.userTransferResult.TransferUserNames &&
                        this.userTransferResult.TransferUserNames.length > 0
                    ) {
                        this.router.navigate(['/user-transfer/personal/pick-user-name']);
                    } else {
                        this.returnUrlSvr.navigateToReturnUrl(true);
                    }
                } else {
                    this.returnUrlSvr.navigateToReturnUrl(true);
                }
            }
        }
    }

    navigateUserTransferPage(returnUrl: string, isInsurancePage: boolean, loaderStatusEmitter: EventEmitter<boolean>) {
        this.trusteerSnippetsService.executeTagmastersEvent('main_account');
        if (!isInsurancePage) {
            window.location.href = returnUrl;
            return;
            // this.returnUrlSvr.returnUrl = returnUrl;
            // this.modalSvc.closeModal();
            // loaderStatusEmitter.emit(true);
        }
        if (this.userTransferResult.TransferReason !== UserTransferReason.none) {
            this.router.navigate(['/user-transfer/personal/auto'], { state: { isInsurancePage: isInsurancePage } });
        } else if (this.userTransferResult.TransferUserNames && this.userTransferResult.TransferUserNames.length > 0) {
            this.router.navigate(['/user-transfer/personal/pick-user-name'], {
                state: { isInsurancePage: isInsurancePage }
            });
        } else {
            this.router.navigate(['/']);
        }
    }

    setLoginPageReturnUrl(returnUrl: string = '') {
        this.returnUrl = returnUrl;
        this.returnUrlSvr.returnUrl = returnUrl;
    }

    setIsLoginPage(isloginPage: boolean) {
        this.loginPageMode = isloginPage;
    }
}
