import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, HostListener, Inject } from '@angular/core';
import { UiHelperService } from '../../../shared/cardholders-core/services/ui-helper.service';
import { AppConfigsService } from '../../../config/app-configs.service';
import { IHomePageHeaderResult } from '../../homepage/models/homepage-header';
import { MainLayoutService } from '../services/main-layout.service';
import { IBackgroundDetails } from '../../homepage/models/homepage-background';
import { Ave8Service } from '../../../shared/cardholders-core/services/ave8.service';
import { Ave10Service } from '../../../shared/cardholders-core/services/ave10.service';
import { ActivatedRoute, NavigationEnd, Router, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';



@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    companyName: string;
    companyLogoImage: string;
    backgroundDetails: IBackgroundDetails;
    isMobile: boolean;
    isMobileByScreen: boolean;
    isMobileLandscape: boolean;
    @Input()
    homepageElement: HTMLElement;
    minimal: boolean;
    subs: Array<Subscription> = [];
    @Output()
    headerSizeChanged = new EventEmitter<boolean>();
    remove: boolean;
    reload: boolean;
    isEve8: boolean;
    isEve10: boolean;
    isNavOpen: boolean;
    isUserLoggedInSub = new BehaviorSubject<boolean>(true);
    isUserLoggedIn: boolean = this.isUserLoggedInSub.value;


    get isSearchOpen(): boolean {
        return this.mainLayoutService.toggleSearchOpen;
    }

    constructor(
        public uiHelper: UiHelperService,
        private appConfigSvc: AppConfigsService,
        public mainLayoutService: MainLayoutService,
        private router: Router,
        private route: ActivatedRoute,
        private ave8Serv: Ave8Service,
        private ave10Serv: Ave10Service
    ) {
        this.companyName = this.appConfigSvc.appConfigs.companyName;
        /*       this.companyLogoImage = '/assets/images/homepage/max-logo.svg';*/
        this.companyLogoImage = '/assets/images/homepage/log-max-yelow-sign.svg'; /*logo for war time*/
        this.isMobile = this.uiHelper.IsMobile(768);
        this.isMobileByScreen = this.uiHelper.IsMobileByScreen(768);
        this.subs[0] = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.route.snapshot),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })
            )
            .subscribe((route: ActivatedRouteSnapshot) => {
                const isHeaderSizeChanged = route.data['minimalHedaer'] !== this.minimal;
                this.minimal = route.data['minimalHedaer'] === true;
                if (isHeaderSizeChanged) {
                    this.headerSizeChanged.emit(this.minimal);
                }
            });
        this.subs.push(
            this.mainLayoutService.isRemoveLayout.subscribe(remove => {
                this.remove = remove;
            })
        );
        this.subs.push(
            this.isUserLoggedInSub.subscribe(isUserLoggedIn => {
                this.isUserLoggedIn = isUserLoggedIn;
            })
        );
    }
    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.reload = true;
        setTimeout(() => {
            this.reload = false;
        }, 200);
    }
    ngOnInit() {
        this.reload = true;
        setTimeout(() => {
            this.reload = false;
        }, 200);
        this.mainLayoutService.loadActions().then((res: IHomePageHeaderResult) => {
            this.backgroundDetails = res.Background;
        });
        this.isMobileLandscape =
            this.uiHelper.isBrowser &&
            this.uiHelper.isMobileRequest &&
            window.matchMedia('(orientation: landscape)').matches;

        if (this.uiHelper.isBrowser) {
            this.isEve8 = this.ave8Serv.isAve8();
            this.isEve10 = this.ave10Serv.isAve10();
        }

        this.subs.push(
            this.mainLayoutService.isNavOpen.subscribe((isNavOpen) => {
                this.isNavOpen = isNavOpen;
            })
        );
    }

    getRootLink() {
        return this.uiHelper.getRootLink();
    }

    getStyleOfHeader() {
        let margin;
        if (this.uiHelper.isBrowser && this.uiHelper.isCookieAppear) {
            margin = this.isMobile ? '11.6rem' : '11rem';
            return { 'margin-top': margin };
        } else {
            margin = this.isMobile ? '6.8rem' : '7rem';
            return { 'margin-top': margin };
        }
    }

    getStyleOfTopHeader() {
        let margin;
        if (this.uiHelper.isBrowser) {
            if (this.uiHelper.isCookieAppear) {
                margin = this.isMobile ? '7.2rem' : '4rem';
                return { 'margin-top': margin };
            } else {
                return { 'margin-top': '0rem' };
            }
        }
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {
        this.isMobileLandscape =
            this.uiHelper.isMobileRequest && !window.matchMedia('(orientation: landscape)').matches;
    }

    getCompanyLogoBackground() {
        if (this.isEve8) {
            return { 'background-color': '#FFFB94' };
        }
        else if (this.isEve10) {
            return { 'background-color': '#daf0ff' };
        }
        else {
            return { 'background-color': 'transparent' };
        }
    }

    // toggleIsUserLoggedIn(value: boolean) {
    //     this.isUserLoggedIn = value;
    // }
}
