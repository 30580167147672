<ng-container  [ngSwitch]="recommendation.RecommendationId">
    <app-loan-product-box [recommendation]="recommendation" [indexOfRecommendation]="indexOfRecommendation" [loanParams]="loanParams" *ngSwitchCase="10"></app-loan-product-box>
    <app-loan-product-box [recommendation]="recommendation" [indexOfRecommendation]="indexOfRecommendation" [loanParams]="bankLoanParams" *ngSwitchCase="23"></app-loan-product-box>

    <div class="product" *ngSwitchCase="17" [ngStyle]="{
            'background-image' : isMobile ?
            'url(' + recommendation.RecommendationBackgroundPicMobile.Url + ')' :
            'url(' + recommendation.RecommendationBackgroundPicDesktop.Url + ')'
         }">
        <h3 [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}">{{recommendation.RecommendationTitle}}</h3>
        <h4 [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}">{{recommendation.RecommendationSubTitle}}</h4>
        <div class="store-wrapper">
            <a href="https://app.appsflyer.com/com.ideomobile.leumicard?pid=site&c=new_app" title=""  class="google-play" [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}"></a>
            <a href="https://app.appsflyer.com/id398915363?pid=site&c=new_app" title="" class="app-store" [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}"></a>
        </div>
        <!--<a appLink [anchorLink]="recommendation.RecommendationLink" class="general-button prod-link">{{recommendation.RecommendationLinkTitle}}</a>-->
    </div>
    <div role="listitem" class="product" *ngSwitchDefault [ngStyle]="{
            'background-image' : isMobile ?
            'url(' + recommendation.RecommendationBackgroundPicMobile.Url + ')' :
            'url(' + recommendation.RecommendationBackgroundPicDesktop.Url + ')'
         }">
        <h3 [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}">{{recommendation.RecommendationTitle}}</h3>
        <h4 [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}">{{recommendation.RecommendationSubTitle}}</h4>
        <a appLink [attr.target]="((recommendation.RecommendationLinkOpenNewTab) ? '_blank' : null)" [anchorLink]="recommendation.RecommendationLink" class="general-button prod-link" (click)="onRecommendationButtonClick(recommendation, indexOfRecommendation)" [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : ''), 'border-color' : ((recommendation.FontColor !== null) ? recommendation.FontColor : '')}">
            <span [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}">{{recommendation.RecommendationLinkTitle}}</span>
        </a>

    </div>
</ng-container>