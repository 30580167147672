import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, AfterViewInit, HostListener } from '@angular/core';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { HomepageService } from '../../services/homepage.service';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { BehaviorSubject } from 'rxjs';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { a11yFix } from 'ClientApp/app/shared/utils/a11y.utils';
@Component({
    selector: 'app-benefits',
    templateUrl: './benefits.component.html',
    styleUrls: ['./benefits.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BenefitsComponent implements OnInit, AfterViewInit {
    isBrowser = false;
    isLoggedIn = this.authService.isUserAuthenticated();
    benefits: any;
    benefitsEligibility: any;
    contentRoot: string;
    title: string;
    titleLink: string;
    titleLinkUrl: string;
    companyName: string;

    benefitsStatusTitle: string;
    benefitsStatusLinkText: string;
    benefitsStatusLinkUrl: string;

    validForHokBenefit = true;

    isLoadedEligibility: BehaviorSubject<boolean>;
    isLoadedBenefits: BehaviorSubject<boolean>;

    slideConfig: any;
    isVipUser: boolean;
    showBenefitStatusBanner = true;
    reload: boolean;

    constructor(
        private uiHelper: UiHelperService,
        private authService: AuthService,
        private homepageService: HomepageService,
        private appConfigsService: AppConfigsService,
        private gtmService: GtmService
    ) {
        this.benefits = undefined;
        this.benefitsEligibility = undefined;
    }
    // @HostListener('window:resize', ['$event'])
    // onResize(event): void {
    //    this.reload = true;
    //    setTimeout(() => {
    //        this.reload = false;
    //    }, 200);
    // }

    ngOnInit() {
        this.reload = true;
        setTimeout(() => {
            this.reload = false;
        }, 200);
        this.isLoadedEligibility = new BehaviorSubject<boolean>(false);
        this.isLoadedBenefits = new BehaviorSubject<boolean>(false);
        this.isBrowser = this.uiHelper.isBrowser;
        this.companyName = this.appConfigsService.appConfigs.companyName;
        this.homepageService.loadBenefits().then(res => {
            this.benefits = res;
            if (this.isBrowser) {
                this.updateLoadStatusBenefits(true);
            }
            // this.cd.detectChanges();
        });
        this.contentRoot = this.appConfigsService.appConfigs.contentRoot;
        this.title = this.isLoggedIn ? 'פינוקים והטבות כשמתחשק' : 'ליהנות מכל ההטבות שמגיעות לך';
        this.titleLink = this.isLoggedIn ? 'לפירוט ההזמנות שלי' : 'לכל ההטבות';
        this.titleLinkUrl = this.isLoggedIn
            ? `${this.appConfigsService.appConfigs.infoRoot}/anonymous/benefits/BenefitsHistory.aspx?sourceGA=HomepageBenefitsOnline`
            : `${this.appConfigsService.appConfigs.contentRoot}/he-il/Benefits/Pages/BenfitsGallery.aspx?sourceGA=HomepageBenefitsMarketing`;
        this.benefitsStatusTitle = 'החודש לא צברת פינוקים,';
        this.benefitsStatusLinkText = 'אבל אפשר לקבל פינוק במתנה בהעברת הוראות קבע בקליק אחד';
        if (this.uiHelper.isBrowser && this.isLoggedIn) {
            this.homepageService.loadBenefitsEligibility().then(res => {
                this.benefitsEligibility = res;
                // this.validForHokBenefit = this.benefitsEligibility.ValidForHokBenefit;
                this.isVipUser =
                    this.benefitsEligibility.BenefitsCustomerType === 'ws' ||
                    this.benefitsEligibility.BenefitsCustomerType === 'first' ||
                    this.benefitsEligibility.BenefitsCustomerType === 'business';
                if (
                    this.benefitsEligibility.BenefitsEligibility === 0 &&
                    this.benefitsEligibility.BenefitsUsage === 0
                ) {
                    if (!this.benefitsEligibility.ValidForHokBenefit) {
                        this.showBenefitStatusBanner = false;
                    }
                    this.benefitsStatusTitle = this.isVipUser ? 'החודש לא צברת פינוקי VIP,' : 'החודש לא צברת פינוקים,';
                    this.benefitsStatusLinkText = 'אבל אפשר לקבל פינוק במתנה בהעברת הוראות קבע בקליק אחד';
                    this.benefitsStatusLinkUrl = `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/card/PermanentTransfer.aspx?addbenefit=true`;
                } else {
                    // this.benefitsEligibility.BenefitsEligibility > 0
                    if (this.benefitsEligibility.BenefitsUsage === this.benefitsEligibility.BenefitsEligibility) {
                        this.benefitsStatusTitle = this.isVipUser
                            ? 'החודש התפנקת בכל הפינוקים VIP שצברת. היה כיף?'
                            : 'החודש התפנקת בכל הפינוקים שצברת. היה כיף?';
                    } else if (this.benefitsEligibility.BenefitsUsage < this.benefitsEligibility.BenefitsEligibility) {
                        this.benefitsStatusTitle = this.isVipUser
                            ? `איזה כיף, יש לך ${this.benefitsEligibility.BenefitsEligibility -
                                  this.benefitsEligibility.BenefitsUsage} פינוקי VIP החודש`
                            : `איזה כיף, יש לך ${this.benefitsEligibility.BenefitsEligibility -
                                  this.benefitsEligibility.BenefitsUsage} פינוקים החודש`;
                    } else {
                        this.benefitsStatusTitle = this.isVipUser
                            ? 'יתרת הפינוקים VIP שלך החודש: 0'
                            : 'יתרת הפינוקים שלך החודש: 0';
                    }

                    this.benefitsStatusLinkText = 'איך מחשבים פינוקים?';
                    this.benefitsStatusLinkUrl = `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/PinukimDetails.aspx`;
                }
                this.updateLoadStatusEligibility(true);
            });
        }

        if (this.isBrowser) {
            this.slideConfig = {
                accessibility: false,
                infinite: true,
                slidesToShow: this.uiHelper.IsMobile(576)
                    ? 1
                    : this.authService.isUserAuthenticated()
                    ? 3
                    : 6 /* after login change to 3 */,
                slidesToScroll: this.uiHelper.IsMobile(576) ? 1 : this.authService.isUserAuthenticated() ? 3 : 2,
                arrows: true,
                variableWidth: this.authService.isUserAuthenticated() ? undefined : true /* before login */,
                variableHeight: this.authService.isUserAuthenticated() ? undefined : true /* before login */,
                centerMode: this.authService.isUserAuthenticated() ? undefined : true /* before login */,
                // centerPadding: this.authService.isUserAuthenticated() ? undefined : '40px' /* before login */,
                speed: 700,
                useTransform: this.authService.isUserAuthenticated() ? undefined : true /* before login */,
                cssEase: this.authService.isUserAuthenticated() ?undefined : 'linear' /* before login */,
                responsive: [
                    {
                        accessibility: false,
                        breakpoint: 576,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            cssEase: 'linear',
                            variableWidth: false,
                            variableHeight: false,
                            centerPadding: '46px',
                            speed: 200,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            waitForAnimate: false
                        }
                    }
                ]
            };
        }
        a11yFix().link(".default-link", {selectors:{element: ".default-link"}});

    }

    ngAfterViewInit(): void {}

    showArrows(): void {
        document.querySelector('.benefits-pinukim .slick-prev')?.classList.add('show-left-arrow');
        document.querySelector('.benefits-pinukim .slick-next')?.classList.add('show-right-arrow');
    }

    hideArrows(): void {
        document.querySelector('.benefits-pinukim .slick-prev')?.classList.remove('show-left-arrow');
        document.querySelector('.benefits-pinukim .slick-next')?.classList.remove('show-right-arrow');
    }

    updateLoadStatusEligibility(loadStatus: boolean): void {
        this.isLoadedEligibility.next(loadStatus);
    }
    updateLoadStatusBenefits(loadStatus: boolean): void {
        this.isLoadedBenefits.next(loadStatus);
    }

    registrationToGtm(event: any) {
        try {
            event.slick.$nextArrow[0].onclick = () => {
                this.gtmService.pushDirective({
                    category: `New website - ${this.authService.isUserAuthenticated() ? 'registered' : 'anonymous'}`,
                    action: 'Click on benefit arrow',
                    label: 'Right'
                });
            };

            event.slick.$prevArrow[0].onclick = () => {
                this.gtmService.pushDirective({
                    category: `New website - ${this.authService.isUserAuthenticated() ? 'registered' : 'anonymous'}`,
                    action: 'Click on benefit arrow',
                    label: 'Left'
                });
            };
        } catch (error) {}
    }

    swipeToGtm() {
        this.gtmService.pushDirective({
            category: `New website - ${this.authService.isUserAuthenticated() ? 'registered' : 'anonymous'}`,
            action: 'Swipe benefit arrow',
            label: 'Direction'
        });
    }

    getBenefitImage(link) {
        if (link.indexOf('http') === -1) {
            return this.contentRoot + link;
        }
        return link;
    }
}
