<div class="new-homepage">
    <!--<app-main-banner></app-main-banner>-->
    <app-main-banner-css></app-main-banner-css>
    <app-what-max-today></app-what-max-today>
    <app-carousel></app-carousel>
    <app-max-application></app-max-application>
    <!-- <app-news></app-news> -->
    <app-more-actions-in-max></app-more-actions-in-max>
    <app-here-for-you-mobile></app-here-for-you-mobile>
</div>
