<form
    [formGroup]="userLoginForm"
    *ngIf="!fido2Info && !fido2Loader; else fingerPrintResult"
    class="user-login-form"
    [ngClass]="{'biometricDisplay': displayBiometric}"
>
    <span
        *ngIf="
            isExtraAuthRequired &&
            !isLoginWithMax &&
            !loginPageMode &&
            !isOpenBankingLogin &&
            !isDigitalTransferCardLogin
        "
        class="title main-title"
        >לפני שנוכל לאפשר את ביצוע הפעולה נעשה זיהוי קצרצר</span
    >
    <span *ngIf="isLoginWithMax" class="max-title title main-title"
        >נכנסים עם שם משתמש וסיסמה זהים לאלו שיש לך באתר ובאפליקציית max</span
    >
    <div class="form-group id-input" *ngIf="needId" id="idInput">
        <input
            formControlName="id"
            appNumberWithoutSymbol
            type="tel"
            class="form-control"
            maxlength="9"
            placeholder="מספר תעודת זהות/דרכון"
            (blur)="idBlured()"
            trim
            #id
            (focus)="putCaretInTheEnd($event)"
        />
        <span *ngIf="isIdNotValidByPattern()" class="error-wrapper"
            ><span class="error-msg">נא למלא עד 9 ספרות, כולל ספרת ביקורת ללא אותיות</span></span
        >
        <a class="tooltip-icon" appTooltip [tooltipText]="idTooltipText" [tooltipHtmlParent]="idInput"></a>
        <span *ngIf="userLoginForm.controls['id'].pristine || !userLoginForm.controls['id'].value" class="error-wrapper"
            ><span class="error-msg">{{ needIdMsg }}</span></span
        >
    </div>
    <div class="form-wrapper">
        <div class="form-group" id="usernameInput">
            <input
                autocomplete="on"
                formControlName="username"
                type="text"
                maxlength="55"
                id="user-name"
                class="form-control"
                placeholder="שם משתמש"
                tabindex="1"
                (focus)="putCaretInTheEnd($event)"
                (blur)="usernameBlured($event)"
                trim
                #username
            />
            <span *ngIf="isUsernameEmpty()" class="error-wrapper"><span class="error-msg">נא למלא שם משתמש</span></span>
            <a
            aria-label="למידע נוסף"
                class="tooltip-icon"
                appTooltip
                [tooltipText]="usernameTooltipText"
                [tooltipHtmlParent]="usernameInput"
            ></a>
        </div>

        <div class="form-group password-input" id="passwordInput">
            <input
                autocomplete="on"
                formControlName="password"
                id="password"
                type="password"
                maxlength="14"
                class="form-control"
                placeholder="סיסמה"
                tabindex="2"
                trim
                (focus)="putCaretInTheEnd($event)"
                (blur)="passwordBlured($event)"
            />
            <span *ngIf="isPasswordEmpty()" class="error-wrapper"><span class="error-msg">נא למלא סיסמה</span></span>
            <span *ngIf="isPasswordNotValidByPattern()" class="error-wrapper"
                ><span class="error-msg">הקלדת אותיות וסימנים לא חוקיים, נמליץ להתחיל מחדש</span></span
            >
            <div class="password">
                <div
                    [attr.aria-label]="passwordShownEye ? 'הסתר סיסמא' : 'הצג סיסמא'"
                    class="revealPassword"
                    [ngClass]="{ passwordVisible: passwordShownEye }"
                    (click)="showHidePassword()"
                ></div>
            </div>
            <a  aria-label="למידע נוסף"
                class="tooltip-icon"
                appTooltip
                [tooltipText]="passwordTooltipText"
                [tooltipHtmlParent]="passwordInput"
            ></a>
        </div>
    </div>
    <a
        *ngIf="!hideForgotPassword"
        [attr.href]="forgotPasswordUrl"
        class="link forgot skip-blur"
        id="forgot"
        title=""
        [attr.target]="isLoginWithMax ? '_blank' : '_self'"
        >אוי, שכחתי</a
    >
    <div class="confirm-terms form-group" *ngIf="displayBiometric">
        <div class="group">
            <input
                type="checkbox"
                [(ngModel)]="useBiometric"
                [ngModelOptions]="{ standalone: true }"
                id="useBiometric"
                required
            />
            <label class="label-terms" for="confirmTerms">
                אני רוצה להצטרף לשירות זיהוי חכם<span
                    class="question-tip"
                    [tooltip]="'זיהוי ביומטרי או קוד אבטחה של המכשיר'"
                ></span>
                ואני מאשר/ת את <a [attr.href]="touchIDAggrement" title="" class="link" target="_blank">התקנון</a></label
            >
        </div>
    </div>
    <span *ngIf="isLoginFailedFewTimes" class="error-msg number-id-fail">
        עליך למלא את מספר תעודת הזהות או הדרכון איתו נרשמת לשירות בשל מספר ניסיונות זיהוי כושלים.
    </span>
    <span *ngIf="showError" class="error-msg bio-error">
        {{ errorMsg }}
    </span>
    <div class="button-wrapper">
        <button (click)="loginClicked()" class="general-button send-me-code " tabindex="3">
            <span>לכניסה לאזור האישי</span>
        </button>
        <span class="not-recive-code" *ngIf="!hideRegisterLink"
            >עדיין לא נרשמת לאזור האישי?
            <a
                [attr.href]="registrationUrl"
                title=""
                class="link skip-blur"
                id="sign-now"
                [attr.target]="isLoginWithMax ? '_blank' : '_self'"
                >כאן נרשמים</a
            ></span
        >
    </div>
</form>

<ng-template #fingerPrintResult>
    <div class="fido-container">
        <div class="fido-text">{{fido2Info}}</div>
        <div *ngIf="fido2Loader" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <button *ngIf="!fido2Loader" (click)="modalSvc.closeModal()" class="general-button send-me-code "  tabindex="3">
            <span>לכניסה לאזור האישי</span>
        </button>
    </div>
    <!-- <app-fingerprint-result
        *ngIf="displayFingerprintResult"
        [isLoginWithMax]="isLoginWithMax"
        [registrationUrl]="registrationUrl"
        [title]="fingerprintTitle"
        [desc]="fingerprintDesc"
        (emitbtn)="loginClicked()"
    ></app-fingerprint-result> -->
</ng-template>
