import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    IndPolicyAuthoRenew,
    IndRenewedPolicy,
    IPolicyData,
    LineCode
} from '../../../models/homepage-insurance-details';
import { UiHelperService } from '../../../../../shared/cardholders-core/services/ui-helper.service';
import { AuthService } from '../../../../../shared/cardholders-core/services/auth.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HomepageService } from '../../../services/homepage.service';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ModalService } from '../../../../../shared/modules/bs-modal/services/modal.service';
import { TechnicalError } from '../../../../../modules/main-layout/constants/modals.consts';
import { AppConfigsService } from '../../../../../config/app-configs.service';
import { GtmService } from '../../../../../shared/cardholders-core/services/gtm.service';
import { IHomePageRecommendationsResult } from '../../../models/homepage-recommendation';
import { InsuranceService } from '../../../services/insurance.service';
import { InsuranceDetailsResult } from '../../../../saving/interfaces/ISavingEligibilityResponse.interface';
import { AgreeInd } from '../../../../saving/interfaces/enum.interface';
import { SidePopupModal } from '../side-popup/constant.modal';

@Component({
    selector: 'app-my-insurance',
    templateUrl: './my-insurance.component.html',
    styleUrls: ['./my-insurance.component.scss']
})
export class MyInsuranceComponent implements OnInit, OnDestroy {
    insuranceCards: IPolicyData[] = [];
    /** To enable appLoader init with true. */
    showMyInsurance = false;
    @Input() newFlow: boolean;
    @Input() isOtp?: boolean;
    @Input() insuranceDetail: InsuranceDetailsResult;
    @Input() agreeInd: AgreeInd;

    showInsuranceAcceptance = false;
    isShowAllInsurance = false;
    isShowInsuranceText = true;
    loader = false;
    myInsuranceSildeConfig = {
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        swipeSpeed: 200,
        autoplay: false,
        mobileFirst: true,
        accessibility: false,
        rtl: true,
        responsive: [
            {
                breakpoint: 767,
                settings: 'unslick'
            }
        ]
    };
    myInsuranceIsLoaded: BehaviorSubject<boolean>;
    showRecommendation = true;
    recommendationIsLoaded: BehaviorSubject<boolean>;
    recommendationsToShow: IHomePageRecommendationsResult[];
    private subs: Subscription[] = [];

    constructor(
        private homepageService: HomepageService,
        private uiHelper: UiHelperService,
        private insuranceHPSrv: InsuranceService,
        private authSvc: AuthService,
        private decimalPipe: DecimalPipe,
        private modalSvc: ModalService,
        private gtmService: GtmService,
        private appConfigService: AppConfigsService,
        private cd: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (!this.appConfigService.appConfigs.FLAG_ISEnableInsuranceDetails) {
            this.showMyInsurance = false;
            return;
        }
        this.myInsuranceIsLoaded = new BehaviorSubject<boolean>(false);
        this.recommendationIsLoaded = new BehaviorSubject<boolean>(false);
        if (this.uiHelper.isBrowser && this.authSvc.isUserAuthenticated()) {
            if (this.newFlow) {
                this.getCardInsuranceDataNewFlow();
            } else {
                this.getCardInsuranceData();
            }

            this.getRecommendationData();
        }
    }

    ngOnDestroy(): void {
        this.subs?.forEach(s => s.unsubscribe());
    }
    getCardInsuranceDataNewFlow() {
        this.insuranceCards = this.insuranceDetail?.insuranceDetailsList.filter(c => c.lineCode !== 12) ?? ([] as any);

        this.showMyInsurance = this.insuranceCards.length > 0 || this.insuranceDetail.isHavePolicy;

        if (this.insuranceCards.length === 0 && !this.insuranceDetail.isHavePolicy) {
            this.showMyInsurance = false;
            return;
        }
        this.pushToGtm('Insurance', this.insuranceCards.length.toString());
        for (let i = 0; i < this.insuranceCards.length; i++) {
            this.insuranceCards[i].fields = this.getFields(this.insuranceCards[i]);
            this.insuranceCards[i].title = this.insuranceCards[i].lineDesc;
            this.insuranceCards[i].showRenewInsurance = this.showRenewButton(this.insuranceCards[i]);
            if (this.insuranceCards[i].showRenewInsurance) {
                this.pushToGtm('Insurance - ' + this.getInsuranceType(this.insuranceCards[i].lineCode), 'Renew button');
            }
            this.pushToGtm(
                'Insurance',
                this.getInsuranceType(this.insuranceCards[i].lineCode) +
                    ' - ' +
                    this.getInsuranceStatus(this.insuranceCards[i].policyStatus)
            );
        }
        if (this.insuranceCards?.length <= 4) {
            this.isShowInsuranceText = false;
        }
        this.myInsuranceIsLoaded.next(true);
        this.showMyInsurance = true;
        this.cd.detectChanges();

        this.showInsuranceAcceptance = this.agreeInd === AgreeInd.NotExist;
    }
    getCardInsuranceData() {
        this.subs.push(
            this.insuranceHPSrv.getInsuranceDetails().subscribe(res => {
                switch (res.returnCode) {
                    case 11:
                        this.pushToGtm('Insurance - not agreement', 'Show');
                        this.showInsuranceAcceptance = true;
                        this.showMyInsurance = true;
                        this.cd.detectChanges();
                        return;
                    case 0:
                        this.insuranceCards = res.result.insuranceDetailsList;
                        if (this.insuranceCards.length === 0) {
                            this.showMyInsurance = false;
                            return;
                        }
                        this.pushToGtm('Insurance', this.insuranceCards.length.toString());
                        for (let i = 0; i < this.insuranceCards.length; i++) {
                            this.insuranceCards[i].fields = this.getFields(this.insuranceCards[i]);
                            this.insuranceCards[i].title = this.insuranceCards[i].lineDesc;
                            this.insuranceCards[i].showRenewInsurance = this.showRenewButton(this.insuranceCards[i]);
                            if (this.insuranceCards[i].showRenewInsurance) {
                                this.pushToGtm(
                                    'Insurance - ' + this.getInsuranceType(this.insuranceCards[i].lineCode),
                                    'Renew button'
                                );
                            }
                            this.pushToGtm(
                                'Insurance',
                                this.getInsuranceType(this.insuranceCards[i].lineCode) +
                                    ' - ' +
                                    this.getInsuranceStatus(this.insuranceCards[i].policyStatus)
                            );
                        }
                        if (this.insuranceCards?.length <= 4) {
                            this.isShowInsuranceText = false;
                        }
                        this.myInsuranceIsLoaded.next(true);
                        this.showMyInsurance = true;
                        this.cd.detectChanges();
                        break;
                    default:
                        this.showMyInsurance = false;
                        return;
                }
            })
        );
    }

    getRecommendationData() {
        this.homepageService.insuranceRecommendationsChanged.subscribe(res => {
            if (res && res.length) {
                this.recommendationsToShow = res;
            } else {
                this.showRecommendation = false;
            }
            this.recommendationIsLoaded.next(true);
            this.cd.detectChanges();
        });

        if (this.homepageService.insuranceRecommendation !== undefined) {
            this.homepageService.insuranceRecommendationsChanged.next(this.homepageService.insuranceRecommendation);
        }
    }

    toggleInsurance() {
        if (!this.isShowAllInsurance) {
            this.pushToGtm('Insurance', 'Click on more insurances');
        }
        this.isShowAllInsurance = !this.isShowAllInsurance;
    }

    acceptInsurance() {
        this.pushToGtm('Insurance - not agreement', 'Click to accept');
        this.homepageService.checkAgreementsStatus(2).subscribe(res => {
            if (res.returnCode === 0) {
                this.router.navigate(['/insurance/personal']);
            } else {
                this.modalSvc.openModal(TechnicalError).subscribe(data => {
                    this.myInsuranceIsLoaded.next(false);
                    this.showInsuranceAcceptance = false;
                    if (this.uiHelper.isBrowser && this.authSvc.isUserAuthenticated()) {
                        this.getCardInsuranceData();
                    }
                });
            }
        });
    }

    insuranceClick(insurance: IPolicyData) {
        this.pushToGtm('Insurance - ' + this.getInsuranceType(insurance.lineCode), 'Click on more information');
        this.navigateToAig(insurance, insurance.externalURL);
    }

    renewInsurance(insurance: IPolicyData) {
        this.pushToGtm('Insurance - ' + this.getInsuranceType(insurance.lineCode), 'Click on renew button');
        this.navigateToAig(insurance, insurance.renewalURL);
    }

    navigateToAig(insurance: IPolicyData, link: string) {
        SidePopupModal.options.initialState = {
            type: 'insurance',
            content: {
                insuranceCompny: insurance.insuranceCompanyName
                }
        } as Object;

        this.pushAigPopUpToGtm("Pop to "+insurance.insuranceCompanyName, "Show");

        this.subs.push(
            this.modalSvc.openModal(SidePopupModal).subscribe((x: { type: string; event: string }) => {
                if (x.type === 'button') {
                    this.pushAigPopUpToGtm("click","Transfer");
                    if (
                        this.appConfigService.appConfigs.FLAG_ISEnableAigSso &&
                            insurance.profileCellularPhone &&
                        insurance.profileCellularPhone.replace(/0/g, '').length > 0 &&
                        this.isOtp
                    ) {
                        this.loader = true;
                        this.subs.push(
                            this.homepageService.getAigSso(insurance.profileCellularPhone).subscribe(x => {
                                this.loader = false;
                                if (x.returnCode === 0 && x.result?.url) {
                                    window.open(x.result.url, '_blank');
                                } else {
                                    // this.openErrorModal();
                                    window.open(link);
                                }
                            })
                        );
                    } else {
                        window.open(link);
                    }
                } else {
                    this.pushAigPopUpToGtm("click", "Not now");
                }
            })
        );
    }

    openErrorModal(): void {
        this.loader = true;
        setTimeout(() => {
            this.loader = false;
            SidePopupModal.options.initialState = {
                type: 'error'
            } as Object;
            this.subs.push(this.modalSvc.openModal(SidePopupModal).subscribe(x => {}));
        }, 1000);
    }

    showRenewButton(insurance: IPolicyData): boolean {
        return (
            this.appConfigService.appConfigs.FLAG_ISShowInsuranceRenewButton &&
            ([1, 21, -121].includes(insurance.lineCode) ||
                (insurance.lineCode === 31 && insurance.indPolicyAuthoRenew !== IndPolicyAuthoRenew.authoRenew)) &&
            moment(insurance.endDate).isBetween(moment().startOf('day'), moment().add(40, 'day'), undefined, '[]') &&
            insurance.policyStatus === 1 &&
            insurance.indRenewedPolicy !== IndRenewedPolicy.renewed
        );
    }

    private getFields(insurance: IPolicyData) {
        const fields: { name: string; value: any; isEllipsis?: boolean }[] = [];
        const primeField = { name: 'פרמיה שנתית', value: '₪' + this.decimalPipe.transform(insurance.primeTotal) };
        let endDateField;
        switch (insurance.policyStatus) {
            case 1:
                endDateField = { name: 'תוקף', value: moment(insurance.endDate).format('DD.MM.YYYY') };
                break;
            case 2:
                endDateField = { name: undefined, value: 'לא בתוקף' };
                break;
            case 3:
                endDateField = { name: undefined, value: 'מבוטלת' };
                break;
            case 4:
                endDateField = {
                    name: 'תוקף',
                    value:
                        moment(insurance.startDate).format('DD.MM.YYYY') +
                        '-' +
                        moment(insurance.endDate).format('DD.MM.YYYY')
                };
                break;
            case 5:
                endDateField = { name: 'בתוקף החל מ', value: moment(insurance.startDate).format('DD.MM.YYYY') };
                break;
        }

        switch (insurance.lineCode) {
            case LineCode.carMekifHova:
            case LineCode.carMekif:
            case LineCode.carHova:
                if (insurance.carModel && insurance.carNumber) {
                    fields.push({ name: 'רכב', value: insurance.carModel + ' ' + insurance.carNumber });
                } else if (insurance.carModel || insurance.carNumber) {
                    fields.push({ name: 'רכב', value: insurance.carModel ? insurance.carModel : insurance.carNumber });
                }
                fields.push(primeField, endDateField);
                break;

            case LineCode.abroad:
                if (insurance.travelDestination) {
                    fields.push({ name: 'יעד נסיעה', value: insurance.travelDestination });
                }
                fields.push({
                    name: 'תאריכי טיסה',
                    value:
                        moment(insurance.startDate).format('DD.MM.YYYY') +
                        '-' +
                        moment(insurance.endDate).format('DD.MM.YYYY')
                });
                if (insurance.travelNumOfInsured) {
                    fields.push({ name: 'מספר מבוטחים', value: insurance.travelNumOfInsured });
                }
                primeField.name = 'פרמיה';
                fields.push(primeField);
                break;

            case LineCode.apartment:
                if (insurance.homePropertyAddress) {
                    fields.push({ name: 'כתובת', value: insurance.homePropertyAddress }); // , isEllipsis: true
                }
                fields.push(primeField, endDateField);
                break;

            case LineCode.mortgage:
                if (insurance.mortgageBank) {
                    fields.push({ name: 'הבנק המלווה', value: insurance.mortgageBank });
                }
                if (insurance.mortgageBeneficiaryName1 && insurance.mortgageBeneficiaryName2) {
                    fields.push({
                        name: 'שמות המבוטחים',
                        value: insurance.mortgageBeneficiaryName1 + ', ' + insurance.mortgageBeneficiaryName2
                    });
                } else if (insurance.mortgageBeneficiaryName1 || insurance.mortgageBeneficiaryName2) {
                    fields.push({
                        name: 'שמות המבוטחים',
                        value: insurance.mortgageBeneficiaryName1
                            ? insurance.mortgageBeneficiaryName1
                            : insurance.mortgageBeneficiaryName2
                    });
                }
                fields.push(endDateField);
                if (insurance.primeTotal) {
                    fields.push(primeField);
                }
                break;
        }

        return fields;
    }

    private pushToGtm(action: string, label: string) {
        this.gtmService.pushTransactionDetailsUpsaleRow(action, label);
    }

    private pushAigPopUpToGtm(action: string, label: string) {
        this.gtmService.pushPopUp("Transfer pop to AIG", action, label);

    }

    private getInsuranceType(lineCode: number) {
        switch (lineCode) {
            case LineCode.carMekifHova:
            case LineCode.carMekif:
            case LineCode.carHova:
                return 'car';
            case LineCode.abroad:
                return 'abroad';
            case LineCode.apartment:
                return 'apartment';
            case LineCode.mortgage:
                return 'mortgage';
        }
    }

    private getInsuranceStatus(policyStatus: number) {
        switch (policyStatus) {
            case 1:
                return 'active';
            case 2:
                return 'expired';
            case 3:
                return 'cancelled';
            case 4:
                return 'not active';
            case 5:
                return 'renewal';
        }
    }
}
