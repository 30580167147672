import { Component, OnInit } from '@angular/core';
import { AppConfigsService } from '../../../config/app-configs.service';

@Component({
  selector: 'app-message-receive',
  templateUrl: './message-receive.component.html'
})

    /**
     * transfer messages via document between other domain to this domain.
     * for 'one time message' - not for data streaming.
    */

export class MessageReceiveComponent implements OnInit {

  constructor(private configService: AppConfigsService) { }

  ngOnInit(): void {
    const configs = this.configService.appConfigs;
    window.addEventListener('message', function onMessageArrival(messageEvent: MessageEvent) {
        if (configs.businessRoot == messageEvent.origin
            && messageEvent?.data?.key === 'businessLastLogin') {
                localStorage.setItem(messageEvent.data.key, messageEvent.data.value);
            }
        /*else if (event.origin='yourorigin'){
            DoSomething...
        }*/

        window.removeEventListener('message', onMessageArrival);
    });
  }
}
