import {
    IHomePageRecommendationsResult,
    ComponentBackground,
    IHomePageRecommendationsDataResult
} from '../../models/homepage-recommendation';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { IHomePageUserData, ForeignCurrency, ICardData, ICycleSummaryNew } from '../../models/homepage-user-data';
import { IResponse, IResponseLower } from '../../../../shared/cardholders-core/services/http-service.service';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { Component, OnInit, OnDestroy, Inject, HostListener, AfterViewInit } from '@angular/core';
import { TechnicalError } from '../../../main-layout/constants/modals.consts';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { HomepageService } from '../../services/homepage.service';
import { DOCUMENT, formatDate } from '@angular/common';
import { Router } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Subscription } from 'rxjs';
import { ILoanParams } from '../../../../shared/cardholders-core/models/loan-params';
import { IMonthlyBillingLayoutHomePage } from '../../models/monthly-billing-layout-home-page.interface';
import { a11yFix } from 'ClientApp/app/shared/utils/a11y.utils';
@Component({
    selector: 'app-my-cards',
    templateUrl: './my-cards.component.html',
    styleUrls: ['./my-cards.component.scss']
})
export class MyCardsComponent implements OnInit, OnDestroy, AfterViewInit {
    userData: IHomePageUserData;
    recommendationsData: IHomePageRecommendationsResult[];
    recommendationsToShow: IHomePageRecommendationsResult[];
    myCardRecommendations: number[];
    userCardsToShow: ICardData[];
    isShowAllCards = false;
    isShowCardsText = true;
    approvedCardsCount: number;
    myCardsIsLoaded: BehaviorSubject<boolean>;
    recommendationIsLoaded: BehaviorSubject<boolean>;
    showRecommendation = true;
    showMyCards = true;
    myCardsShowLoaderBlink = true;
    backgroundFirstCardRec: ComponentBackground;
    backgroundSecondCardRec: ComponentBackground;
    idTooltipText =
        'קיימת מסגרת משותפת לכרטיס. יתרת המסגרת לשימוש מושפעת גם מחיובים בכרטיסי max הבנקאים הנוספים בחשבון';
    digitalCardTooltipText =
        'הכרטיס הדיגיטלי ישמש אותך לרכישה באתרים או לתשלום באמצעות הנייד בחנויות (התומכות בכך) עד שהכרטיס הפיזי יגיע עם שליח או בדואר.';
    hasTooltip = false;
    loanParams: ILoanParams;
    monthlyBillingLayoutHomePage: IMonthlyBillingLayoutHomePage;
    ismobile: boolean;
    flagIsUserById: boolean;
    private subs: Subscription[] = [];
    flagIsUseViewCardDetailsInd: boolean;

    get showMyMaxTooltip(): boolean {
        return this.userData &&
        this.userData.UserCards.Cards.some(c => !c.IsMyMAX) &&
        this.userData.UserCards.Cards.some(c => c.IsMyMAX);
    }

    constructor(
        @Inject(DOCUMENT) private document: any,
        private authSvc: AuthService,
        private uiHelper: UiHelperService,
        private homepageService: HomepageService,
        private appConfigsService: AppConfigsService,
        private modalSvc: ModalService,
        private gtmService: GtmService,
        private router: Router
    ) {
        this.flagIsUserById = this.appConfigsService.appConfigs.FLAG_ISUserById;
        this.flagIsUseViewCardDetailsInd = this.appConfigsService.appConfigs.FLAG_IsUseViewCardDetailsInd;
    }
    ngAfterViewInit(): void {
        a11yFix().tooltip('.tooltip-icon',{
            selectors:{
                trigger:'.tooltip-icon',
                tooltip:'.tool-tip-text'
            }
        });
        setTimeout(() => {
            let tooltipIcons = document.querySelectorAll('.tooltip-icon');
                tooltipIcons.forEach((trigger)=>{
                    trigger.setAttribute("aria-lable","מידע נוסף");
                })
            }, 1000);

    }

    @HostListener('document:click', ['$event'])
    clickOut(event: any): void {
        if (this.uiHelper.IsMobile(768) && this.hasTooltip) {
            event.stopPropagation();
            event.preventDefault();
            this.hasTooltip = false;

        }
    }
    ngOnInit(): void {
        this.ismobile = this.uiHelper.IsMobile(768);
        this.myCardsIsLoaded = new BehaviorSubject(false);
        this.recommendationIsLoaded = new BehaviorSubject<boolean>(false);
        if (this.uiHelper.isBrowser && this.authSvc.isUserAuthenticated()) {
            this.getHomePageData();
            this.getRecommendationData();
            this.getMonthlyBillingLayoutHomePage();
        }
        this.homepageService.productsChanged.subscribe(res => {
            this.loanParams = res.Result.LoanParams;
        });
        // a11yFix().link(".tooltip-icon", {selectors:{element: ".tooltip-icon"}});


    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    getHomePageData(): void {
        this.subs.push(
            this.homepageService.getHomePageData().subscribe((res: IResponse<IHomePageUserData>) => {
                switch (res.ReturnCode) {
                    case 0: {
                        if (res.Result) {
                            this.userData = res.Result;
                            this.setShowCards();
                        } else {
                            this.showMyCards = false;
                        }
                        this.myCardsIsLoaded.next(true);
                        break;
                    }
                    case 9: {
                        this.myCardsShowLoaderBlink = false;
                        this.modalSvc.openModal(TechnicalError).subscribe(data => {
                            this.myCardsShowLoaderBlink = true;
                            this.getHomePageData();
                        });
                        break;
                    }
                    default: {
                        this.showMyCards = false;
                        break;
                    }
                }
            })
        );
    }

    getRecommendationData(): void {
        this.homepageService.getRecommendationDataResult().subscribe((res: IHomePageRecommendationsDataResult) => {
            if (res) {
                this.recommendationsToShow = res.HomePageRecommendationsResults.filter(rec => rec !== undefined);
                this.backgroundFirstCardRec = res.BackgroundRecResult.BackgroundFirstCardRec;
                this.backgroundSecondCardRec = res.BackgroundRecResult.BackgroundSecondCardRec;
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Number of propositions',
                    label: `${this.recommendationsToShow.length > 1 ? 2 : 1} - Debits`
                });
                this.showRecommendation = this.recommendationsToShow.length >= 1;
                this.recommendationsToShow.forEach(r => {
                    this.gtmService.pushDirective({
                        category: 'deals details dashboard',
                        action: '20',
                        label: r.ExternalRecommendationId,
                        value: '1'
                    });
                });
            } else {
                this.showRecommendation = false;
            }
            this.recommendationIsLoaded.next(true);
        });
    }

    onClickRecommendation(recommendation: IHomePageRecommendationsResult): void {
        this.gtmService.pushDirective({
            category: 'deals details dashboard',
            action: '20',
            label: recommendation.ExternalRecommendationId,
            value: '2'
        });
    }

    setShowCards(): void {
        this.userData.UserCards.Cards = this.userData.UserCards.Cards.filter(card => card.ReturnCode === 0 && !card.IsMyMAX);
        this.approvedCardsCount = this.userData.UserCards.Cards.length;
        if (this.userData.UserCards.Cards.length <= 4) {
            this.userCardsToShow = this.userData.UserCards.Cards;
            this.isShowCardsText = false;
        } else {
            this.userCardsToShow = this.userData.UserCards.Cards.slice(0, 4);
        }
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Number of cards',
            label: this.userCardsToShow.length.toString()
        });
        if (this.userCardsToShow.find(x => x.IsControlsBiZCardSubscribe)) {
            this.gtmService.pushControl('New website - registered', 'Managing  expense budget', 'show');
        }
    }
    goToControl(event: any): void {
        event.stopPropagation();
        this.gtmService.pushControl(
            'New website - card actions',
            'Managing  expense budget',
            'Click- more info and update'
        );
        this.router.navigate(['/control/personal', 'apply'], { queryParams: { SourceGA: 'homepage' } });
    }

    toggleCards(): void {
        this.isShowAllCards = !this.isShowAllCards;
        this.userCardsToShow = this.isShowAllCards
            ? this.userData.UserCards.Cards
            : this.userData.UserCards.Cards.slice(0, 4);
    }

    checkCardSummaryStatus(index: number): boolean {
        let result = 0;
        this.userData.UserCards.Cards[index].CycleSummary.forEach(sum => (result += sum.TotalDebitSum));
        return result === 0;
    }

    redirectToDebitPage(index: number, date: any, event: any): void {
        if (event.target.id === 'clickableLink') {
            event.preventDefault();
        } else if (!this.uiHelper.IsMobile(768) || !this.hasTooltip) {
            this.router.navigate(['transaction-details/personal'], {
                queryParams: {
                    filter: `-1_${index}_1_${date.split('T')[0]}_0_0_-1`,
                    sort: '1a_1a_1a_1a_1a_1a'
                }
            });
        }
    }

    getDebitSumText(index: number): string {
        const card: ICardData = this.userData.UserCards.Cards[index];
        let actualNISDebitSum = 0, totalNISDebitSum = 0;
        if (card  && card.CycleSummary) {
            const cIndex = card.CycleSummary.findIndex(c => c.Currency === ForeignCurrency.Nis);
            if (cIndex !== -1) {
                actualNISDebitSum =  card.CycleSummary[cIndex].ActualDebitSum;
                totalNISDebitSum =  card.CycleSummary[cIndex].TotalDebitSum;
            }
        }
        let result = ' סה״כ ';
        let addedStatus = false;
        let debitSum: number;

        if (actualNISDebitSum > 0 && actualNISDebitSum !== totalNISDebitSum) {
            card.CycleSummary.forEach(summary => {
                if (summary.ActualDebitSum !== summary.TotalDebitSum) {
                    debitSum =
                        summary.Currency === ForeignCurrency.Nis ? summary.ActualDebitSum : summary.TotalDebitSum;

                    if (debitSum > 0) {
                        if (addedStatus) {
                            result += ' + ';
                        }

                        result +=
                            debitSum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + summary.CurrencySymbol;
                        addedStatus = true;
                    }
                }
            });
        }

        if (addedStatus) {
            result += ' יחוייבו ';
        }

        return result + ' ב ';
    }

    checkIfFixedDebit(index: number): boolean {
        let card: ICardData;
        card = this.userData.UserCards.Cards[index];

        if (!card &&
            !card.CycleSummary) {
            return false;
        }
        const cIndex = card.CycleSummary.findIndex(c => c.Currency === ForeignCurrency.Nis);
    if (cIndex !== -1) {
            return (
                card.CycleSummary[cIndex].ActualDebitSum > 0 &&
                card.CycleSummary[cIndex].ActualDebitSum !== card.CycleSummary[cIndex].TotalDebitSum
            );
        }
        return false;
    }

    checkTotalSummaryStatus(): boolean {
        let result = 0;
        this.userData.UserCards.Summary.forEach(sum => (result += sum.TotalDebitSum));
        return result === 0;
    }

    checkCardTotalSummaryStatus(cycleSummary: ICycleSummaryNew[] ): boolean {
        let result = 0;
        cycleSummary.forEach(sum => (result += sum.TotalDebitSum));
        return result === 0;
    }

    calculateWidth(openToBuy: number, creditLimit: number): string {
        const result =
            creditLimit === 0
                ? 0 + '%'
                : openToBuy >= 0
                    ? ((creditLimit - openToBuy) / creditLimit) * 100 + '%'
                    : 100 + '%';
        return result;
    }

    goToMonthlyBillingLayout(): void {
        this.gtmService.pushMonthlyBillingRegistered('117', '2');
        if (this.monthlyBillingLayoutHomePage.isMonthlyBillingLayoutUpdate) {
            this.gtmService.pushMonthlyBillingLayoutJoinUpdateCancel('click to update', 'from dashboard');
        } else {
            this.gtmService.pushMonthlyBillingLayoutWorkFlow('Click to join', 'From dashboard', '1');
        }

        this.router.navigate(['/monthly-billing-layout/personal']);
    }

    goToMonthlyBillingPastLayout(): void {
        this.gtmService.pushMonthlyBillingLayoutWorkFlow('Click to join', 'From dashboard', '1');
        this.gtmService.pushMonthlyBillingRegistered('116', '2');
        this.router.navigate(['/past-monthly-billing-layout/personal']);
    }

    showMonthlyBillingLayout(label: string): void {
        this.gtmService.pushMonthlyBillingRegistered(label, '1');
    }

    getMonthlyBillingLayoutHomePage(): void {
        this.homepageService
            .getMonthlyBillingLayoutHomePage()
            .subscribe((res: IResponseLower<IMonthlyBillingLayoutHomePage>) => {
                this.monthlyBillingLayoutHomePage = res.result;
            });
    }
}
