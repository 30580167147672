import { Component, HostListener, OnInit } from '@angular/core';
import { ICardPointsData, ICardPointsResult } from '../../models/homepage-points-data';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { HomepageService } from '../../services/homepage.service';
import {
    IHomePageRecommendationsResult,
    ComponentBackground,
    IHomePageRecommendationsDataResult
} from '../../models/homepage-recommendation';
import { Subscription, BehaviorSubject } from 'rxjs';
import { IResponse } from 'ClientApp/app/shared/cardholders-core/services/http-service.service';
import { AppConfigsService } from 'ClientApp/app/config/app-configs.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-my-points',
    templateUrl: './my-points.component.html',
    styleUrls: ['./my-points.component.scss']
})
export class MyPointsComponent {
    cardPoints: ICardPointsData[] = [];
    recommendationsData: IHomePageRecommendationsResult[];
    recommendationsToShow: IHomePageRecommendationsResult[];
    isShowAllPoints = false;
    isShowPointsText = true;
    isMultUsers: boolean;
    infoRoot: string;
    backgroundFirstPointRec: ComponentBackground;
    myPointsSildeConfig = {
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        swipeSpeed: 200,
        autoplay: false,
        mobileFirst: true,
        accessibility: false,
        rtl: true,
        responsive: [
            {
                breakpoint: 767,
                settings: 'unslick'
            }
        ]
    };
    myPointsIsLoaded: BehaviorSubject<boolean>;
    recommendationIsLoaded: BehaviorSubject<boolean>;
    showMyPoints = true;
    showRecommendation = true;
    reload: boolean;
    private subs: Subscription[] = [];

    constructor(
        private authSvc: AuthService,
        private uiHelper: UiHelperService,
        private homepageService: HomepageService,
        private gtmService: GtmService,
        private router: Router
    ) {
        this.infoRoot = this.homepageService.appConfigsService.appConfigs.infoRoot;
    }
    //@HostListener('window:resize', ['$event'])
    //onResize(event): void {
    //    this.reload = true;
    //    setTimeout(() => {
    //        this.reload = false;
    //    }, 50);
    //}

    ngOnInit() {
        this.myPointsIsLoaded = new BehaviorSubject<boolean>(false);
        this.recommendationIsLoaded = new BehaviorSubject<boolean>(false);
        if (this.uiHelper.isBrowser && this.authSvc.isUserAuthenticated()) {
            this.getCardPointsData();
            this.getRecommendationData();
        }
        this.reload = true;
        setTimeout(() => {
            this.reload = false;
        }, 50);
    }

    getCardPointsData() {
        this.subs.push(
            this.homepageService.getCardPointsData().subscribe((res: IResponse<ICardPointsResult>) => {
                if (res.ReturnCode === 0) {
                    if (res.Result && res.Result.CardPointsData && res.Result.CardPointsData.length > 0) {
                        this.cardPoints = res.Result.CardPointsData;
                        this.isMultUsers = res.Result.IsMultUsers;
                        this.hitGtm(this.cardPoints);
                        if (this.cardPoints.length <= 4) {
                            this.isShowPointsText = false;
                        }
                    } else {
                        this.showMyPoints = false;
                    }
                    this.myPointsIsLoaded.next(true);
                } else {
                    // show technical error popup
                }
            })
        );
    }

    getRecommendationData() {
        this.homepageService.pointsRecommendationsChanged.subscribe((res: IHomePageRecommendationsDataResult) => {
            if (res && res.HomePageRecommendationsResults?.length) {
                this.recommendationsToShow = res.HomePageRecommendationsResults;
                this.backgroundFirstPointRec = res.BackgroundRecResult.BackgroundFirstPointRec;
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Number of propositions',
                    label: '1 - Points'
                });
                if (this.recommendationsToShow[0].ExternalRecommendationId) {
                    this.gtmService.pushDirective({
                        category: 'points and payback',
                        action: '54',
                        label: this.recommendationsToShow[0].ExternalRecommendationId,
                        value: '1'
                    });
                }
            } else {
                this.showRecommendation = false;
            }
            this.recommendationIsLoaded.next(true);
        });
    }

    onRecommendationClick(recommendation: IHomePageRecommendationsResult): void {
        if (recommendation.ExternalRecommendationId) {
            this.gtmService.pushDirective({
                category: 'points and payback',
                action: '54',
                label: recommendation.ExternalRecommendationId,
                value: '2'
            });
        }
    }

    togglePoints() {
        this.isShowAllPoints = !this.isShowAllPoints;
    }

    hitGtm(cardsPoints: any) {
        const result = [];
        for (const cardPoint of cardsPoints) {
            if (cardPoint.IsPayBack) {
                result.push('Payback-money');
            }
            if (cardPoint.FlightPoints) {
                result.push('Flight');
            }
            if (cardPoint.CardPoints) {
                result.push('DreamCard');
            }
            if (cardPoint.CashBackPoints && !cardPoint.IsPayBack) {
                result.push('TotalPayback');
            }
            if (cardPoint.IsSkyMax) {
                result.push('SkyMax');
            }
        }
        for (const type of result.filter((v, i, a) => a.indexOf(v) === i)) {
            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: `Accumulation type - ${type}`,
                label: result.filter((x) => x === type).length.toString()
            });
        }
    }
    goToTotalMaxback(): void {
        this.router.navigateByUrl('/total-max-back/personal/main?sourcega=homepage');
    }
}
