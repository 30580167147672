<div class="main-banner" [ngClass]="{'pause-animation': !isAnimationPlay}">
    <div class="main-title">
        <h1 class="first-title">
            {{ content?.firstTitle }}
        </h1>
        <h2 class="second-title">{{ content?.secondTitle }}</h2>
        <a aria-hidden="true" class="home-button home-button-anim hide-desk" title="" id="order-card" appLink [anchorLink]="button?.url" [isPreventDefaultForRouterLink]="true" [href]="button?.url" (click)="onButtonClick()">{{ button?.text }}</a>
    </div>

    <div class="cards-wrapper">
        <div class="white-card-wrapper">
            <img src="../assets/images/new-homepage/white-card.svg" class="white-card" />
            <img src="../assets/images/new-homepage/white-card-shadow.png" class="shadow-white" />
        </div>
        <div class="blue-card-wrapper">
            <img src="../assets/images/new-homepage/blue-card.svg" class="blue-card" />
            <img src="../assets/images/new-homepage/white-card-shadow.png" class="shadow-blue" />
        </div>
        <div class="pink-card-wrapper">
            <span class="remove-after-anim"> <img src="../assets/images/new-homepage/peach-card.svg" class="pink-card"></span>
            <img src="../assets/images/new-homepage/white-card-shadow.png" class="shadow-pink">
            <img src="../assets/images/new-homepage/white-card-shadow.png" class="shadow-pink-anim">
            <span class="brith-after-anim-mobile-wrapper"> <img src="../assets/images/new-homepage/peach-card.svg" class="brith-after-anim-mobile"></span>
        </div>

    </div>
    <div class="cards-wrapper-desktop">
        <div class="pink-card-wrapper">
            <img src="../assets/images/new-homepage/blue-card.svg" class="pink-card-desktop pink-card-anim"/>
            <img src="../assets/images/new-homepage/white-card.svg" class="pink-card-desktop card-thickness"/>
            <img src="../assets/images/new-homepage/white-card-shadow.png" class="shadow-pink-desktop"/>
        </div>
        <div class="pink-card-wrapper-right">
            <img src="../assets/images/new-homepage/peach-card.svg" class="pink-card-desktop pink-card-right-anim"/>
            <img src="../assets/images/new-homepage/white-card.svg" class="pink-card-desktop card-thickness"/>
            <img src="../assets/images/new-homepage/white-card-shadow.png" class="shadow-pink-desktop shadow-pink-desktop-anim "/>
        </div>
    </div>

    <div class="iphone-wrapper">
        <img src="../assets/images/new-homepage/New_Mockup_01.svg" class="iphone"/>
        <!--<img src="../assets/images/new-homepage/New_Mockup_01.png" class="iphone">-->

    </div>
    <div class="common-actions-wrapper" u1-visible="true">
        <div class="white-card-wrapper">
            <img src="../assets/images/new-homepage/Max_Back_Card_Clean.svg" class="white-card-desktop white-card-desktop-anim"/>
            <img src="../assets/images/new-homepage/Max_Back_Card_Clean.svg" class="end-shadow"/>
            <img src="../assets/images/new-homepage/white-card-shadow.png" class="shadow-white-desktop shadow-white-desktop-anim "/>
        </div>
        <div class="blue-card-wrapper">
            <span class="remove-peach-after-anim"> <img src="../assets/images/new-homepage/peach-card.svg" class="blue-card-desktop blue-card-desktop-anim"></span>
            <img src="../assets/images/new-homepage/white-card-shadow.png" class="shadow-blue-desktop"/>
            <span class="brith-shadow-after-anim"><img src="../assets/images/new-homepage/white-card-shadow.png" class="shadow-after-anim"/></span>
            <span class="brith-aftre-anim-wrapper"> <img src="../assets/images/new-homepage/peach-card.svg" class="brith-aftre-anim"/></span>
        </div>
        <div class="iphone-wrapper-desktop">
            <img src="../assets/images/new-homepage/iPhone-14-Pro-no-card.svg" class="iphone" />
        </div>
        <div aria-hidden="true" class="main-title-after-anim">
            <h2 aria-hidden="true">{{ content?.secondTitle }}</h2>
            <a
                class="home-button order-card"
                title=""
                id="order-card"
                appLink
                [anchorLink]="button?.url"
                [isPreventDefaultForRouterLink]="true"
                [href]="button?.url"
                (click)="onButtonClick()"
                >{{ button?.text }}</a
            >
            <!--<img src="../assets/images/new-homepage/New_Mockup_01.png" class="iphone">-->

        </div>
        <div class="main-title-after-anim">
                    <h2>{{ content?.secondTitle }}</h2>
                    <a class="  home-button order-card" title="" id="order-card" appLink [anchorLink]="button?.url" [isPreventDefaultForRouterLink]="true" [href]="button?.url" (click)="onButtonClick()">{{ button?.text }}</a>
         </div>
        <div class="common-actions">
            <h3>פעולות מהירות</h3>
            <ul class="link-wrapper">
                <li *ngFor="let action of content?.actionsList; let i = index">
                    <a class="link-action" title="" [id]="'action-' + i"
                       appLink [anchorLink]="action.button.url" [isPreventDefaultForRouterLink]="true" [href]="action.button.url" (click)="onActionClick(action.button.text)">  <img [src]="action.icon" class="link-icon" aria-hidden="true"/><span class="link-text"> {{ action.button.text }}</span></a>
                </li>

            </ul>
        </div>


    </div>

    <div class="play-pause-animation" aria-label="כפתור עצירה" (click)="onAnimationPauseClick()">
        <span class="play-button"></span>
    </div>
</div>