<div class="personal-area" [ngClass]="{ 'personal-area-open': isUserBoxOpen }">
    <div u1-visible="true"
        class="user-details-wrapper"
        [ngClass]="{
            'local-storage-status-mobile': !isNeedToShowBeforeLoginMenu && !isAuthenticated() && isMobile,
            'rotate-arrow': !isUserLoggedIn
        }"
        (click)="isAuthenticated() ? toggleUserBox() : toggleBeforeLoginMenu()"
        tabindex=""
    >
        <span
            *ngIf="isAuthenticated(); else loginLink"
            class="user-details d-block d-sm-block d-md-none"
            [ngStyle]="getCompanyLogoBackground()"
        >
        </span>
        <ng-template #loginLink>
            <a
                title=""
                class="user-details d-block d-sm-block d-md-none"
                [ngStyle]="{ 'background-color': isEve8 ? '#FFFB94' : 'transparent' }"
            ></a>
        </ng-template>
        <div   u1-visible="true" class="menu-before-login" [ngClass]="{ 'display-none': isUserLoggedIn || isAuthenticated() }">
            <a  aria-label="כניסה לאזור אישי - לקוחות פרטיים"
                class="login-link"
                title=""
                id="private"
              (click)="onLoginClick()"
              (keydown)="onPrivateKeyDown($event)"
            >
                <img src="../assets/images/homepage/avatar-icon-before-log.svg" alt="" class="link-icon" />
                <span>לקוחות פרטיים</span>
            </a>
            <a  aria-label="כניסה לאזור אישי - לקוחות עסקיים"
                class="login-link"
                title=""
                id="business"
                (click)="navigateToBusinessLogin()"
                (keydown)="onBusinessKeyDown($event)"
                appGtm


                [data]="{
                    category: 'new website anonymous',
                    action: 'enter personal area',
                    label: 'business'
                }"
                listen="onclick"
            >
                <img src="../assets/images/homepage/business-icon.svg" alt="" class="link-icon" />
                <span>לקוחות עסקיים</span>
            </a>
        </div>
    </div>

    <a
        *ngIf="isAuthenticated() && isMobile"
        class="letters"
        href="{{ infoRoot }}/Registred/Services/InboxAndMailing.aspx?SourceGA=HeaderLogin&Tab=letters"
    >
        <span class="mobile-number" *ngIf="unreadLetters > 0 && unreadLetters <= 9">{{ unreadLetters }}</span>
        <span class="mobile-number oval-mobile-number" *ngIf="unreadLetters > 9">+9</span>
    </a>

    <div
        class="user-tooltip"
        id="info"
        [ngClass]="{ 'show-user-tooltip': isUserBoxOpen }"
        [ngStyle]="{ top: '-190px' }"
    >
        <div *ngIf="userbox.UserName !== null" class="mail">
            <span>{{ showUserName ? userbox.UserName : '&nbsp;' }}</span>
        </div>

        <div class="content">
            <ul id="userBoxList">
                <li *ngIf="userbox.IsShowUserSettings" (keydown)="onUserBoxKeyDown($event)">
                    <a
                        href="{{ infoRoot }}/Registred/UserSettings/UserSettings.aspx?SourceGA=PersonalMenu"
                        title=""
                        appGtm
                        [data]="{
                            category: 'New website - registered',
                            action: 'Personal menu',
                            label: 'Enter personal data'
                        }"
                        listen="onclick"
                        >הגדרות ופרטים אישיים</a
                    >
                </li>
                <li *ngIf="userbox.IsShowInboxAndMailing" (keydown)="onUserBoxKeyDown($event)">
                    <a
                        href="{{ infoRoot }}/Registred/Services/InboxAndMailing.aspx?SourceGA= PersonalMenu&Tab=letters"
                        title=""
                        appGtm
                        [data]="{
                            category: 'New website - registered',
                            action: 'Personal menu',
                            label: unreadLetters ? 'Enter personal mail' : 'Enter personal mail - no new mail'
                        }"
                        listen="onclick"
                    >
                        המכתבים ודו"ח האשראי שלך
                        <span class="unread-letters" *ngIf="unreadLetters > 0 && unreadLetters <= 9">
                            {{ unreadLetters }}
                        </span>
                        <span class="unread-letters oval-unread-letters" *ngIf="unreadLetters > 9">+9</span>
                    </a>
                </li>

                <li
                    *ngIf="!userbox.IsOtp && !fido2Exists && userbox.IsShowBiometric && showFido2"
                    class="add-finger-print hide-desktop"
                    listen="onclick"
                    (keydown)="onUserBoxKeyDown($event)"
                >
                    <span (click)="addFidoCredentials()">הצטרפות לשירות זיהוי חכם </span>
                </li>
                <li
                    *ngIf="!userbox.IsOtp && fido2Exists && userbox.IsShowBiometric && showFido2"
                    (click)="deleteFidoCredentials()"
                    (keydown)="onUserBoxKeyDown($event)"
                    class="add-finger-print hide-desktop"
                    listen="onclick"
                >
                    הסרת אפשרות זיהוי חכם
                </li>

                <li>
                    <a
                        href="{{ businessRoot }}/auth/login?utm_source=privateweb_login"
                        target="_blank"
                        title=""
                        (keydown)="onUserBoxKeyDown($event)"
                        appGtm
                        [data]="{
                            category: 'new website - registered',
                            action: 'personal menu',
                            label: 'enter business web'
                        }"
                        listen="onclick"
                        >התחברות לאתר העסקי</a
                    >
                </li>
            </ul>

            <div class="change-account">
                <a *ngIf="isAdmin" class="change-account-switch" (click)="changeAccount()" title=""
                    >החלף משתמש התחזות</a
                >
                <a
                    appGtm
                    [data]="{ category: 'New website - registered', action: 'Personal menu', label: 'Log out' }"
                    (click)="logout()"
                    (keydown)="onLogoutKeyDown($event)"
                    tabindex="0"
                    id="logout"
                    title=""
                    >התנתקו</a
                >
            </div>

            <div *ngIf="fido2Info" class="fingerprint-message">
                <div class="close-message" (click)="fido2Info = null"></div>
                <p class="message">{{ fido2Info }}</p>
                <div *ngIf="fido2Loader">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a
        *ngIf="!minimal && !flagIsNeedToShowBeforeLoginMenu"
        href="{{
            isAuthenticated()
                ? businessRoot + '?sourcega=RegisteredHeaderCH'
                : businessRoot + '?sourcega=AnonymousHeaderCH'
        }}"
        class="to-bussiness-website d-none d-sm-none d-md-block"
        appGtm
        [data]="{ category: 'New website', action: 'Enter to business site', label: 'Click' }"
        [configuration]="{ registered: ' - registered', anonymous: ' - anonymous', before: false }"
        listen="onclick"
        target="_blank"
        >לאתר העסקי</a
    >
    <div *ngIf="isAuthenticated(); else personalAreaExpander" class="personal-area">
        <div [attr.aria-expanded]="isUserBoxOpen ? 'true' : 'false' "
            (click)="toggleUserBox()"
            (keydown)="onPersonalAuthenticatedKeyDown($event)"
            id="personalAuthenticated"
            class="go-to-personal-area log-in-status d-none d-sm-none d-md-block"
            appGtm
            [active]="isUserBoxOpen"
            [data]="{ category: 'New website - registered', action: 'Personal menu', label: 'Click' }"
            listen="onclick"
        >
        <span class="name-wrapper">
            היי {{ userbox.FirstName }} (:
        </span>
        </div>
        <a
            href="{{ infoRoot }}/Registred/Services/InboxAndMailing.aspx?SourceGA=HeaderLogin&Tab=letters"
            class="message-alert-desktop"
            *ngIf="unreadLetters > 0 && !uiHelper.IsMobile(768) && !isMobileLandscape"
            appGtm
            [data]="{ category: 'New website - registered', action: 'Mail icon', label: 'Enter' }"
            listen="onclick"
        >
            <span class="number" *ngIf="unreadLetters > 0 && unreadLetters <= 9">{{ unreadLetters }}</span>
            <span class="number oval-desktop-number" *ngIf="unreadLetters > 9">+9</span>
        </a>
    </div>

    <ng-template u1-visible="true" #personalAreaExpander>
        <div *ngIf="isHeaderTransparent" id="openSearchBoxDesktop" class="open-search-box-desktop"  aria-label="חיפוש" (click)="onSearchClick($event)"></div>

        <a [attr.aria-expanded]="isMenuOpen ? 'true' : 'false' "
            (click)="toggleBeforeLoginMenu()"
            id="personal-entrance"
            class="go-to-personal-area log-in-status d-none d-sm-none d-md-block"
            [ngClass]="{
                'local-storage-status-desktop': !isNeedToShowBeforeLoginMenu && !isAuthenticated(),
                'rotate-arrow': !isUserLoggedIn
            }"
            (keydown)="onPersonalEntranceKeyDown($event)"
        >
            <span class="personal-text">כניסה לאיזור האישי</span>
            <!-- <img src="../assets/images/homepage/user-icon.svg" alt="" class="user-icon" /> -->
        </a>
    </ng-template>
</div>
