<nav class=" navbar navbar-expand-md navbar-dark main-menu">
    <button class="navbar-toggler" (click)="toggleNav()" type="button" data-toggle="collapse" aria-expanded="false" aria-label="תפריט ניווט">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [collapse]="navbarCollapsed">
        <div class="nav-bar-wrapper">
            <ul  aria-live="off" class="navbar-nav">
                <li *ngIf="!uiHelper.IsMobileByScreen(768) && !isMobileLandscape" [ngClass]="{'nav-item-max-logo' : companyName === 'max' && !isUserAuthenticated}"><a u1-link appLink [anchorLink]="getRootLink()" [isOpenLinkInNewTab]="false" title="" class="logo-wrapper"><img [src]="companyLogoImage" alt="לוגו אתר מקס, מעבר לעמוד הבית" class="menu-logo" [ngStyle]="getCompanyLogoBackground()" [ngClass]="companyName === 'max' ? isUserAuthenticated ? 'lc-max-logo' : 'max-logo' : ''" /></a></li>
                <li *ngFor="let category of menu.Categories; let index = index" class="nav-item dropdown" dropdown #dropdown="bs-dropdown" (mouseenter)="onMouseEnter(dropdown)" (mouseleave)="onMouseLeave(dropdown)" (click)="onCategoryClick(dropdown, 'dropdown-menu-' + index)" >
                    <a  tabindex="0"  [attr.aria-expanded]="isNavOpen ? 'true' :'false'"  [ngClass]="{'open-for-loan': category.Name === 'הלוואות'}"  class="nav-link dropdown-toggle" appLinkRoles [noRolesReturnUrl]="getCategoryLink(category)" [roles]="getCategoryLinkRoles(category)" appLink [anchorLink]="getCategoryLink(category)" [isOpenLinkInNewTab]="category.IsOpenLinkInNewTab" [attr.target]="getCategoryLink(category) != undefined && category.IsOpenLinkInNewTab ? '_blank' : undefined" dropdownToggle appGtm [data]="{category: 'New website', action: 'Header link click', label: category.Name}" [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}" listen="onclick">{{ getFixedNameIE(category.Name) }}</a>
                    <div class="dropdown-menu ng-star-inserted" aria-labelledby="dropdown01" [ngStyle]="getStyleOfDropdown()" [id]="'dropdown-menu-' + index">
                        <div class="back-menu">
                            <div class="container">
                                <ul *ngFor="let subCategory of category.SubCategories" class="sub-menu" #subMenu role="presentation">
                                    <li (click)="onSubMenuClick($event, subMenu)">
                                        <a  tabindex="0"  aria-level="2" role="heading" [ngClass]="{'open-for-loan': subCategory.Name === 'הלוואות'}" title="">{{ subCategory.Name === '' ? '&nbsp;' : getFixedNameIE(subCategory.Name) }}</a>
                                        <ul class="sub-menu-links">
                                             <li *ngFor="let menuItem of subCategory.MenuItems"> <a *ngIf="!menuItem.DisableCommboxAccess || !authService.isUserInRoleName('COMMBOX')" [style]="getMenuItemColor(menuItem.color)" class="dropdown-item" appLinkRoles (click)="linkClicked($event)" [roles]="getMenuItemLinkRegisteredRoles(menuItem)" [noRolesReturnUrl]="getMenuItemLink(menuItem)" appLink [anchorLink]="getMenuItemLink(menuItem)" [isOpenLinkInNewTab]="menuItem.IsOpenLinkInNewTab" [isPreventDefaultForRouterLink]="true" [href]="getMenuItemLink(menuItem)" [attr.target]="menuItem.IsOpenItemLinkInNewTab ? '_blank' : ''" appGtm [data]="{category: 'New website', action: 'Header link click', label: menuItem.Name}" [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}" listen="onclick">{{ getFixedNameIE(menuItem.Name) }}</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li *ngIf="isShowInMenuGoToBusinessUrl && minimal && !isMobile && !flagIsNeedToShowBeforeLoginMenu" class="nav-item to-business-item">
                    <a href="{{businessRoot}}" class="nav-link to-bussiness-website" appGtm [data]="{category: 'New website', action: 'Enter to business site', label: 'click'}" listen="onclick" [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}" target="_blank">לאתר העסקי</a>
                </li>
                <li *ngIf="isShowInMenuGoToBusinessUrl && isMobile && !flagIsNeedToShowBeforeLoginMenu" class="nav-item to-business-website">
                    <a href="{{businessRoot}}" class="nav-link to-bussiness-website" appGtm [data]="{category: 'New website', action: 'Enter to business site', label: 'click'}" listen="onclick" [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}" target="_blank">לאתר העסקי</a>
                </li>
            </ul>
            <div class="close-nav-bar-menu" (click)="toggleNav()"></div>
        </div>
    </div>
</nav>