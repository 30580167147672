import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';
import { AuthService, Roles } from "../services/auth.service";
import { UiHelperService } from "../services/ui-helper.service";

@Directive({
    selector: '[ComadminDisabled]'
})

export class ComadminDisabledDirective implements OnInit{
    isAnchor: boolean = false;

    constructor(private elementRef: ElementRef, private render: Renderer2,
        private authSvc: AuthService, private uiHelper: UiHelperService) { }

    ngOnInit(): void {
        if (this.uiHelper.isBrowser) {
            if (this.authSvc.isUserInRole(Roles.ADMIN)) {
                console.log('disabled for comadmin !!!');
                this.setElementStyle();
            }
        }
    }

    setElementStyle() {
        let element = this.elementRef.nativeElement['tagName'].toLowerCase();
        this.isAnchor = element === 'a';
        if (this.isAnchor) {
            this.render.setStyle(this.elementRef.nativeElement, 'visibility', 'hidden');
            this.render.setAttribute(this.elementRef.nativeElement, 'disabled', 'disabled');


        } else {
            this.render.setStyle(this.elementRef.nativeElement, 'background-color', 'grey');
            this.render.setAttribute(this.elementRef.nativeElement, 'disabled', 'disabled');
        }

    }
}