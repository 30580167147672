<div class="min-search-wrapper" [attr.u1-visible]="toggleSearchOpen ? true : false" [ngClass]="{'search-open': toggleSearchOpen, 'search-focus': isSearchFocused, 'local-storage-status': !isNeedToShowBeforeLoginMenu}">
    <input type="checkbox" class="search-box-toggler" [(ngModel)]="checkboxValue" aria-label="תפריט חיפוש" (click)="toggle()" [ngClass]="{ 'left-search-icon-with-local-storage': isNeedToShowBeforeLoginMenu }"/>
    <div aria-hidden="false" tabindex="-1" class="search-box-container" [ngClass]="{'search-open': toggleSearchOpen}">
        <div class="search-box-back"></div>
        <div id="search-box-search" class="search-box-search">
            <div class="icon-container ng-star-inserted" (click)="onSearchClick($event)" u1-button aria-label="חיפוש">
                <i class="icon"></i>
            </div>
        </div>
        <!--<input type="text" #searchBoxTerm class="search-box-term" placeholder="חיפוש" autocomplete="off" (keyup.enter)="onKeyUp($event)">-->
        <div class="search-input-wrapper">
            <span class="small-search-icon"></span>
            <p-autoComplete #searchBoxTerm  [inputId]="'searchBoxTermInput'" (onKeyUp)="onSearchInputKeyUp($event)" (onFocus)="onInputFocus()" (onBlur)="onInputBlur()" [(ngModel)]="textInInput" class="auto" [suggestions]="results" (completeMethod)="filterSearch($event)" [size]="20" placeholder="" (keyup.enter)="onKeyUp($event)" (onSelect)="onSelect($event)">
            </p-autoComplete>
     <label class="search-text" for="searchBoxTermInput"><span class="what-search">מה לחפש?</span>הטבות, הלוואות, כרטיסים...<span class="u1st-sr-only">לחיפוש לחץ Enter</span></label>        </div>
        <div class="search-shadow" (click)="toggleAndChangeCheckboxManually()"></div>
    </div>
</div>