import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { GenericSsoService } from "../../generic-sso.service";

@Component({
    selector: 'app-sso-with-max',
    templateUrl: './sso-with-max.component.html',
    styleUrls: ['./sso-with-max.component.scss']
})
export class SsoWithMaxComponent implements OnInit {
    systemId: string;
    constructor(private router: Router,
        private _activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this._activatedRoute.queryParams.subscribe(params => {
            this.systemId = params.system;
        });
        this.router.navigate(['login'], { queryParams: { utm_source: `sso_${this.systemId}` } });
    }
}
